<template>
    <div class="rapid-renewal--view">
        <section>
            <bxs-layout>
                <bxs-loader
                v-if="loading"
                class="mb-ui"></bxs-loader>

                <div>
                    <div
                    v-if="!loading"
                    class="mb-ui">
                        <div class="mb-12">
                            <bxs-title class="mb-ui">Rinnova la tua tessera</bxs-title>

                            <bxs-card class="pa-ui">
                                <div class="bxs-truncated mb-ui">
                                    <h6>{{ $t('Rinnova la tessera') }}</h6>
                                    <p>{{ card.name }}</p>
                                </div>

                                <div class="mb-ui">
                                    <p class="text-mute">Periodo validità</p>

                                    <p>{{ card.duration }} mesi</p>
                                </div>

                                <div class="mb-ui">
                                    <p class="text-mute">Prezzo</p>
                                    <p>{{ card.price.toFixed(2).replace('.', ',') }} €</p>
                                </div>

                                <div>
                                    <bxs-loader
                                    v-if="pp_loading"
                                    class="mb-ui"></bxs-loader>

                                    <div>
                                        <div
                                        v-show="!pp_loading && !$leila.me.is.active_card"
                                        id="paypal-button-container"
                                        style="width: 100%;"
                                        class="mt-ui"></div>

                                        <bxs-alert
                                        v-show="!pp_loading && $leila.me.is.active_card"
                                        class="mt-ui">La tua tessera è attiva! Potrai rinnovarla una volta scaduta. <br> Ti ricordiamo che il rinnovo sarà disponibile a partire dal {{ $filters.toHumanDate(last_membership.sub_date.to, 'LL', 1) }}</bxs-alert>
                                    </div>
                                </div>
                            </bxs-card>
                        </div>

                        <div>
                            <bxs-title class="mb-ui">Il tuo ultimo tesseramento</bxs-title>

                            <hr class="mb-ui">

                            <div v-if="last_membership">
                                <div class="bxs-truncated mb-ui">
                                    <h6>{{ last_membership.card.name }}</h6>
                                </div>

                                <div
                                v-if="last_membership.numero_tessera_convenzionata"
                                class="mb-ui">
                                    <p class="text-mute">Numero tessera convenzionata</p>

                                    <p>{{ last_membership.numero_tessera_convenzionata }}</p>
                                </div>

                                <div class="mb-ui">
                                    <p class="text-mute">Periodo validità</p>

                                    <p>
                                        <span>{{ $filters.toHumanDate(last_membership.sub_date.from) }}</span>
                                        <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                        <span>{{ $filters.toHumanDate(last_membership.sub_date.to) }}</span>
                                    </p>
                                </div>

                                <div>
                                    <p class="text-mute">Data creazione</p>

                                    <p>{{ $filters.toHumanDate(last_membership.origin_created_at, 'LLL') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
// "buyer": {
//     "email": "sb-uxcap26022810_api1.business.example.com",
//     "password": "325PEPJYCM44YLR7"
// },
// "customer": {
//     "email": "sb-rnvio26284664@personal.example.com",
//     "password": "+W)ZL0}v"
// }

export default {
    name: 'rapid_renewal',
    data () {
        return {
            card: null,
            me: null,
            last_membership: null,
            loading: true,
            pp_loading: true,
            error: null
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            try {
                this.card = this.$store.state.library.renewal_card
                this.me = await this.$leila.me.get()
                const memberships = await this.$leila.me.getMemberships()
                this.last_membership = memberships.length > 0 ? memberships[0] : null
                this.loading = false

                await this.paypal().load()
                this.paypal().init()
                this.pp_loading = false
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        },
        paypal () {
            return {
                load: () => {
                    console.log('load paypal')

                    return new Promise((resolve, reject) => {
                        const script = document.createElement('script')
                        script.setAttribute('data-sdk-integration-source', 'button-factory')
                        const pk = this.$store.state.library.pp_pk // 'AcvcIXorz3s7nW2cE_BnIoiHbPbLzy-jbfvwFOXjTqJ0HQUS7PyzhzqNhsWpnMB6IBcI9ZkhVAZrVbwI'
                        // const query_components = '&components=buttons'
                        const query = '?client-id=' + pk + '&currency=EUR&disable-funding=card,credit,bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo'
                        script.src = 'https://www.paypal.com/sdk/js' + query
                        // data-sdk-integration-source="button-factory"
                        // script.setAttribute('data-sdk-integration-source', 'button-factory')
                        script.addEventListener('load', resolve)
                        document.body.appendChild(script)
                    })
                },
                init: () => {
                    // eslint-disable-next-line
                    return paypal.Buttons({
                        style: {
                            shape: 'rect',
                            color: 'blue',
                            layout: 'vertical',
                            label: 'paypal'
                        },
                        createOrder: (data, actions) => {
                            console.log('paypal createOrder()', data)

                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            currency_code: 'EUR',
                                            value: this.card.price
                                        }
                                    }
                                ]
                            })
                        },
                        onCancel: (data, actions) => {
                            console.log('paypal onCancel()', data)
                        },
                        onApprove: (data, actions) => {
                            console.log('paypal onApprove()', data, actions)

                            return actions.order.capture().then((d) => {
                                console.log('paypal onApprove().order.capture()', d)

                                return this.$leila.post('/v1/me/memberships', {
                                    paypal: {
                                        order_id: d.id
                                    },
                                    card: {
                                        name: this.card.name,
                                        id: this.card.id,
                                        duration: this.card.duration,
                                        price: this.card.price
                                    }
                                }).then(() => {
                                    this.$toast.success('Tessera rinnovata con successo!')
                                    this.$router.go(-1)
                                }).catch((err) => {
                                    this.error = err
                                    console.log('paypal onApprove() server call error', err)
                                })
                            }).catch((err) => {
                                this.error = err
                                console.log('paypal onApprove() order.capture() error', err)
                            })
                        },
                        //
                        payment: (data, actions) => {
                            console.log('paypal payment()', data)
                        },
                        onAuthorize: (data, actions) => {
                            console.log('paypal onAuthorize()', data)
                        },
                        onError: (err) => {
                            this.$store.commit('set_is_loading', false)
                            this.error = err
                            console.log('paypal onError()', err)
                        }
                    }).render('#paypal-button-container')
                }
            }
        }
    }
}
</script>