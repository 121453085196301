import Resource from './BaseResource'

class Catalog extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)
    }

    async getProducts(slug, q) {
        const res = await this.tools.http.get(`${this.api}/${slug}`, q)
        return res
    }

    async getHighlightedProducts(q) {
        const res = await this.tools.http.get(`${this.api}/products/highlighted`, q)
        return res
    }

    async getHighlightedProductsZoho(q) {
        const res = await this.tools.http.get(`${this.api}/products/highlighteds/zoho`, q)
        return res
    }

    async getProduct(slug_id, q) {
        const res = await this.tools.http.get(`${this.api}/products/${slug_id}`, q)
        return res
    }

    async getProductLoans(slug_id, q) {
        const res = await this.tools.http.get(`${this.api}/products/${slug_id}/loans`, q)
        return res
    }

    async getProductReviews(slug_id, q) {
        const res = await this.tools.http.get(`${this.api}/products/${slug_id}/reviews`, q)
        return res
    }
}

export default Catalog