/* eslint-disable */

export default {
    'Abbandona il checkout': 'Abbandona il checkout',
    'Abbandona questo prestito e riparti': 'Abbandona questo prestito e riparti',
    'Abbandona questo prestito': 'Abbandona questo prestito',
    'Abbiamo inviato un\'email a': 'Abbiamo inviato un\'email a',
    'Accedi con openID': 'Accedi con openID',
    'Accedi e prenota!': 'Accedi e prenota!',
    'Accedi': 'Accedi',
    'Ancora nessun oggetto': 'Ancora nessun oggetto',
    'Annulla e chiudi': 'Annulla e chiudi',
    'Annulla prestito': 'Annulla prestito',
    'Annullati': 'Annullati',
    'Annullato': 'Annullato',
    'Approvati': 'Approvati',
    'Approvato': 'Approvato',
    'Attesa conferma': 'Attesa conferma',
    'Attrezzi': 'Attrezzi',
    'Avvisami quando rientra': 'Avvisami quando rientra',
    'Bimbi': 'Bimbi',
    'Bisogno di aiuto? Contattaci': 'Bisogno di aiuto? Contattaci',
    'Bisogno di aiuto?': 'Bisogno di aiuto?',
    'Cambia password': 'Cambia password',
    'Campeggio': 'Campeggio',
    'Carica il tuo oggetto': 'Carica il tuo oggetto',
    'Carica il tuo oggetto': 'Carica il tuo oggetto',
    'Casa': 'Casa',
    'Categorie': 'Categorie',
    'Cerca gli oggetti': 'Cerca gli oggetti',
    'Cerca per categoria': 'Cerca gli oggetti',
    'Checkout in corso': 'Checkout in corso',
    'Ci vogliono': 'Ci vogliono',
    'Ciao': 'Ciao',
    'Codice deposito': 'Codice deposito',
    'Codice ritiro': 'Codice ritiro',
    'Come funziona': 'Come funziona',
    'Condividere': 'Condividere',
    'Condividereis the newpossedere': 'Condividereis the newpossedere',
    'Condivisi': 'Condivisi',
    'Conferma annulla prestito': 'Conferma annulla prestito',
    'Conferma e invia prenotazione': 'Conferma e invia prenotazione',
    'Conferma': 'Conferma',
    'Consegna a domicilio': 'Consegna a domicilio',
    'Contatti e assistenza': 'Contatti e assistenza',
    'Contatti': 'Contatti',
    'Continua con questo prestito': 'Continua',
    'Continua il checkout': 'Continua il checkout',
    'Continua': 'Continua',
    'Cucina': 'Cucina',
    'Data richiesta': 'Data richiesta',
    'Data richiesta': 'Data richiesta',
    'Descrizione ancora non disponibile': 'Descrizione ancora non disponibile',
    'Descrizione': 'Descrizione',
    'Direttamente a casa tua con un contribuito di': 'Direttamente a casa tua con un contribuito di',
    'Direttamente a casa tua!': 'Direttamente a casa tua!',
    'Direttamente in': 'Direttamente in',
    'Disponibili': 'Disponibili',
    'Domenica': 'Domenica',
    'Domicilio': 'Domicilio',
    'Email invitata': 'Email invitata',
    'Esci': 'Esci',
    'Estendi prestito': 'Estendi prestito',
    'Estesi': 'Estesi',
    'Esteso': 'Esteso',
    'Giardinaggio': 'Giardinaggio',
    'Giochi': 'Giochi',
    'Giovedi': 'Giovedi',
    'Grazie per la tua prenotazione!': 'Grazie per la tua prenotazione!',
    'Grazie per la tua recensione. Buona condivisione!': 'Grazie per la tua recensione. Buona condivisione!',
    'Hai bisogno di aiuto? Contattaci': 'Hai bisogno di aiuto? Contattaci',
    'Hai delle domande?': 'Hai delle domande?',
    'Hai già recensito questo oggetto!': 'Hai già recensito questo oggetto!',
    'Hai già un account? Accedi': 'Hai già un account? Accedi',
    'Hai già un profilo? Accedi': 'Hai già un profilo? Accedi',
    'Hobby': 'Hobby',
    'Home': 'Home',
    'I miei oggetti': 'I miei oggetti',
    'I miei prestiti': 'I miei prestiti',
    'I miei prestiti': 'I miei prestiti',
    'I miei tesseramenti': 'I miei tesseramenti',
    'I tuoi oggetti': 'I tuoi oggetti',
    'I tuoi prestiti': 'I tuoi prestiti',
    'Il mio profilo': 'Il mio profilo',
    'Il periodo di prestito verrà calcolato e concordato in base alla data della consegna.': 'Il periodo di prestito verrà calcolato e concordato in base alla data della consegna.',
    'Il periodo di prestito verrà calcolato in base alla data della consegna': 'Il periodo di prestito verrà calcolato in base alla data della consegna',
    'Il tuo feedback': 'Il tuo feedback',
    'Il tuo prestito': 'Il tuo prestito',
    'Il tuo profilo': 'Il tuo profilo',
    'Impossibile trovare': 'Impossibile trovare',
    'Imposta o recupera la password': 'Imposta o recupera la password',
    'In prestito': 'In prestito',
    'In questa sezione troverai lo storico dei tuoi oggetti': 'In questa sezione troverai lo storico dei tuoi oggetti',
    'Indirizzo di consegna': 'Indirizzo di consegna',
    'Informazioni base': 'Informazioni base',
    'Inglese': 'Inglese',
    'Inserisci il codice ricevuto via email': 'Inserisci il codice ricevuto via email',
    'Inserisci qui le tue domande!': 'Inserisci qui le tue domande!',
    'Invia recensione': 'Invia recensione',
    'Iscrizioni': 'Iscrizioni',
    'Istruzioni smartlocker': 'Istruzioni smartlocker',
    'Italiano': 'Italiano',
    'L\'immagine ha solo lo scopo di rappresentare il prodotto, quello presente in biblioteca potrebbe essere differente sia nel colore che nel modello.': 'L\'immagine ha solo lo scopo di rappresentare il prodotto, quello presente in biblioteca potrebbe essere differente sia nel colore che nel modello.',
    'L\'oggetto dovrà essere restituito presso il corner di appartenenza o concordato, se hai necessità diverse,': 'L\'oggetto dovrà essere restituito presso il corner di appartenenza o concordato, se hai necessità diverse,',
    'L\'oggetto dovrà essere restituito presso il corner di appartenenza o concordato, se hai necessità diverse,': 'L\'oggetto dovrà essere restituito presso il corner di appartenenza o concordato, se hai necessità diverse,',
    'L\'oggetto dovrà essere restituito presso il corner dov\'è stato ritirato, se hai necessità diverse': 'L\'oggetto dovrà essere restituito presso il corner dov\'è stato ritirato, se hai necessità diverse',
    'L\'oggetto dovrà essere restituito presso il corner dov\'è stato ritirato, se hai necessità diverse': 'L\'oggetto dovrà essere restituito presso il corner dov\'è stato ritirato, se hai necessità diverse',
    'La tua recensione': 'La tua recensione',
    'Lascia una recensione': 'Lascia una recensione',
    'Lingua': 'Lingua',
    'Link utili': 'Link utili',
    'Lunedi': 'Lunedi',
    'Martedi': 'Martedi',
    'Mercoledi': 'Mercoledi',
    'Musica': 'Musica',
    'Ne hai bisogno urgente? Contattaci': 'Ne hai bisogno urgente? Contattaci',
    'Negati': 'Negati',
    'Negato': 'Negato',
    'Neonati': 'Neonati',
    'Non hai ancora effettuato prestiti': 'Non hai ancora effettuato prestiti',
    'Non hai ricevuto il codice via email? Richiedi': 'Non hai ricevuto il codice via email? Richiedi',
    'Non hai ricevuto l\'email? Prova questi suggerimenti del nostro Centro assistenza.': 'Non hai ricevuto l\'email? Prova questi suggerimenti del nostro Centro assistenza.',
    'Non è più possibile annullare questo prestito!': 'Non è più possibile annullare questo prestito!',
    'Note': 'Note',
    'Oggetti': 'Oggetti',
    'Oggetti in evidenza': 'Oggetti in evidenza',
    'Oggetto in manutenzione': 'Oggetto in manutenzione',
    'Oggetto in prestito': 'Oggetto in prestito',
    'Oggetto': 'Oggetto',
    'Ops, al momento non è possibile annullare il prestito, riprova più tardi': 'Ops, al momento non è possibile annullare il prestito, riprova più tardi',
    'Orari di apertura': 'Orari di apertura',
    'Ovviamente non c\'è bisogno di attendere la scadenza del prestito per restituire l\'oggetto, gli altri soci non potranno che esserne felici.': 'Ovviamente non c\'è bisogno di attendere la scadenza del prestito per restituire l\'oggetto, gli altri soci non potranno che esserne felici.',
    'Ovviamente non c\'è bisogno di attendere la scadenza del prestito': 'Ovviamente non c\'è bisogno di attendere la scadenza del prestito',
    'Passsword aggiornata!': 'Passsword aggiornata!',
    'Pending': 'Pending',
    'Periodo di prestito richiesto': 'Periodo di prestito richiesto',
    'Periodo di prestito': 'Periodo di prestito',
    'Prendi in prestito': 'Prendi in prestito',
    'Prenotazione completata': 'Prenotazione completata',
    'Prestito': 'Prestito',
    'Profilo': 'Profilo',
    'Prossimi giorni di chiusura': 'Prossimi giorni di chiusura',
    'Prova a effettuare nuovamente la ricerca.': 'Prova a effettuare nuovamente la ricerca.',
    'Pulisci filtri': 'Pulisci filtri',
    'Punti di consegna e ritiro': 'Punti di consegna e ritiro',
    'Punto di riconsegna': 'Punto di riconsegna',
    'Punto di ritiro': 'Punto di ritiro',
    'Qual è il tuo indirizzo email?': 'Qual è il tuo indirizzo email?',
    'Qui puoi consultare lo storico dei tuoi prestiti non appena li avrai effettuati.': 'Qui puoi consultare lo storico dei tuoi prestiti non appena li avrai effettuati.',
    'Recap prestito': 'Recap prestito',
    'Recensioni': 'Recensioni',
    'Recupero password': 'Recupero password',
    'Registrati': 'Registrati',
    'Regolamento': 'Regolamento',
    'Reset password': 'Reset password',
    'Restituiti': 'Restituiti',
    'Restituito': 'Restituito',
    'Riceverai una mail di conferma con le istruzioni, controlla nella cartella spam se non ricevi nessuna comunicazione.': 'Riceverai una mail di conferma con le istruzioni, controlla nella cartella spam se non ricevi nessuna comunicazione.',
    'Ricordati, hai 10 minuti per concludere l\'ordine con PayPal.': 'Ricordati, hai 10 minuti per concludere l\'ordine con PayPal.',
    'Ricordati, hai 10 minuti per concludere l\'ordine con PayPal.': 'Ricordati, hai 10 minuti per concludere l\'ordine con PayPal.',
    'Rinnova la mia tessera': 'Rinnovo tessera',
    'Riprova': 'Riprova',
    'Risposte alle tue domande': 'Risposte alle tue domande',
    'Ritiro oggetto': 'Ritiro oggetto',
    'Ritorna ai prestiti': 'Ritorna ai prestiti',
    'Sabato': 'Sabato',
    'Sanitari': 'Sanitari',
    'Scegli il punto di ritiro': 'Scegli il punto di ritiro',
    'Se hai bisogno del trasporto o di ritirarlo con più urgenza': 'Se hai bisogno del trasporto o di ritirarlo con più urgenza',
    'Se ne hai bisogno urgente prova a telefonarci e ci mettiamo d\'accordo': 'Se ne hai bisogno urgente prova a telefonarci e ci mettiamo d\'accordo',
    'Se questa email è collegata all\'account Leila, potrai reimpostare la password.': 'Se questa email è collegata all\'account Leila, potrai reimpostare la password.',
    'Se è la prima volta che accedi usa la mail che hai fornito quando ti sei iscritto all\'associazione Leila.': 'Se è la prima volta che accedi usa la mail che hai fornito quando ti sei iscritto all\'associazione Leila.',
    'Seleziona la lingua': 'Seleziona la lingua',
    'Si trova in': 'Si trova in',
    'Specifica tutto in questo spazio, ti risponderemo appena possibile.': 'Specifica tutto in questo spazio, ti risponderemo appena possibile.',
    'Sport': 'Sport',
    'Stato': 'Stato',
    'Stoviglioteca': 'Stoviglioteca',
    'Supporto': 'Supporto',
    'Tecnologie': 'Tecnologie',
    'Tessera attiva': 'Tessera attiva',
    'Tessera scaduta': 'Tessera scaduta',
    'Trovi le istruzioni per il contibuito richiesto nella prossima schermata.': 'Trovi le istruzioni per il contibuito richiesto nella prossima schermata.',
    'Tutti gli oggetti': 'Tutti gli oggetti',
    'Ultimi arrivi': 'Ultimi arrivi',
    'Vedi istruzioni': 'Vedi istruzioni',
    'Vedi manuale di istruzioni': 'Vedi manuale di istruzioni',
    'Vedi tutti': 'Vedi tutti',
    'Venerdi': 'Venerdi',
    'Verifica': 'Verifica',
    'Vieni a ritirare il tuo oggetto a partire': 'Vieni a ritirare il tuo oggetto a partire',
    'Vieni a ritirare il tuo oggetto': 'Vieni a ritirare il tuo oggetto',
    'Visita il nostro sito': 'Visita il nostro sito',
    'Vota selezionando le stelline e scrivi il tuo messaggio': 'Vota selezionando le stelline e scrivi il tuo messaggio',
    'Vuoi iniziare a condividere? Entra a far parte della comunità!': 'Vuoi iniziare a condividere? Entra a far parte della comunità!',
    'a partire dal': 'a partire dal',
    'che troviamo una soluzione.': 'che troviamo una soluzione.',
    'con un contribuito di': 'con un contribuito di',
    'contattaci': 'contattaci',
    'dal': 'dal',
    'disponibili': 'disponibili',
    'fino al': 'fino al',
    'oggetti': 'oggetti',
    'per preparare l\'oggetto per il ritiro': 'per preparare l\'oggetto per il ritiro',
    'per restituire l\'oggetto, gli altri soci non potranno che esserne felici.': 'per restituire l\'oggetto, gli altri soci non potranno che esserne felici.',
    'possedere': 'possedere',
    'Prestiti': 'Prestiti',
    'prestiti totali': 'prestiti totali',
    'quindi puoi venire a prenderlo': 'quindi puoi venire a prenderlo',
    'Chiudi': 'Chiudi',
    'Scan': 'Scan oggetto',
    'home_disclaimer': 'Con la biblioteca degli oggetti puoi prendere in prestito gratuitamente tutto ciò che ti serve, senza doverlo comprare!',
    'welcome': 'Benvenutə!',
    'Inizia': 'Inizia',
    'Vedi F.A.Q.': 'Vedi F.A.Q.'
}