<template>
    <div class="faq-view">
        <bxs-top-nav>F.A.Q.</bxs-top-nav>

        <section>
            <bxs-layout>
                <div class="text-center">
                    <!-- :href="toPdf(item)" -->
                    <bxs-btn
                    block
                    outlined
                    target="_blank"
                    @click="toPDF">{{ $t('Vedi F.A.Q.') }}</bxs-btn>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
// function generateZohoPublicFileUrl({
//     filename,
//     report = 'Immagini_Report',
//     report_record_id = '133006000002544081',
//     field_name = 'Image',
//     field_action = 'image-download',
//     base_url = 'https://creatorapp.zohopublic.eu/file/leilaitalia/library-management',
//     report_auth_code = 'FhzaByFderHUXkZsKZWS2U5TvOPbUq3RyfpmS9yNyXWpKh5PaHvRtekmZGuEbZsXhU6wg5472yB7hPJADyj0VbH2sFTCyKHveMaY'
// }) {
//     if (!filename) return ''
//     return `${base_url}/${report}/${report_record_id}/${field_name}/${field_action}/${report_auth_code}?filepath=/${filename}`
// }

export default {
    name: 'faq',
    methods: {
        toPDF () {
            this.$store.commit('set_is_loading', true)

            // const splitted_url = this.$store.state.library.faq_pdf_url.split('?')
            // const splitted_filename = splitted_url[1].split('=')
            // const filename = splitted_filename[1]

            // return generateZohoPublicFileUrl({
            //     base_url: 'https://creatorapp.zohopublic.eu/leilaitalia/library-management/report',
            //     report: 'Files_della_biblioteca',
            //     report_record_id: this.$store.state.library.id,
            //     field_name: 'Faq_pdf1',
            //     field_action: 'download-file',
            //     report_auth_code: 'QSwHpVhbAew3JVEeT92e02GbKe0O2taFt1xxhPmadP45G9t3eMgauWPBDmU3Y7DSBaV9DtY0HsAA6NOOd6x2ukFpZOn4QRA88V5S',
            //     filename: filename // + '&digestValue=eyJsYW5ndWFnZSI6Iml0In0='
            // })

            const params = new URLSearchParams({
                filename: '/api/v2/leilaitalia/library-management/report/Biblioteche_Report/133006000003437013/Faq_pdf1/download?filepath=1743585093980_Library_of_Things_FAQ.pdf', // this.$store.state.library.faq_pdf_url,
                report: 'Files_della_biblioteca',
                report_record_id: this.$store.state.library.id,
                field_name: 'Faq_pdf1',
                field_action: 'download-file',
                report_auth_code: 'QSwHpVhbAew3JVEeT92e02GbKe0O2taFt1xxhPmadP45G9t3eMgauWPBDmU3Y7DSBaV9DtY0HsAA6NOOd6x2ukFpZOn4QRA88V5S'
            })

            fetch(`http://localhost:5000/v1/catalogs/z/p/files?${params.toString()}`)
            .then(res => res.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob)
                window.open(url)
                this.$store.commit('set_is_loading', false)

                // const link = document.createElement('a')
                // link.href = URL.createObjectURL(blob)
                // link.download = 'file.pdf'
                // link.click()
            }).catch(() => {
                this.$store.commit('set_is_loading', false)
            })
        }
    }
}
</script>