<template>
    <div class="regolamento-view">
        <bxs-top-nav>{{ $t('Regolamento') }}</bxs-top-nav>

        <section>
            <bxs-layout>
                <div
                v-html="$store.state.library.regolamento"
                class="wysiwyg"></div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'regolamento'
}
</script>