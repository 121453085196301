<template>
    <div
    :class="['collapse-item-comp', {
        actived: on,
        'collapse-item-comp--no-border': hideBorder
    }]"
    @click="toggle">
        <div
        class="collapse-item-comp--head-cnt">
            <slot name="label" />
            <bxs-spacer></bxs-spacer>
            <bxs-icon
            width="1.25em"
            :name="!on ? 'chevron-down' : 'chevron-top'" />
        </div>

        <div
        ref="content"
        class="collapse-item-comp--cnt">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'collapse-item',
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        'hide-border': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            on: false
        }
    },
    methods: {
        toggle () {
            this.on = !this.on

            this.$nextTick(() => {
                const cnt = this.$refs.content

                if (cnt.style.maxHeight) {
                    cnt.style.maxHeight = null
                } else {
                    cnt.style.maxHeight = cnt.scrollHeight + 'px'
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

.collapse-item-comp {
    position: relative;
    border-bottom: 1px solid var(--divider-color);

    &.actived {
        > .collapse-item-comp--cnt {
            // padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }

    &--no-border {
        border: none;
    }

    &--head-cnt {
        position: relative;
        width: 100%;
        min-height: var(--collapse-item-min-height);

        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        cursor: pointer;
    }

    &--cnt {
        padding-top: 0.5rem;
        max-height: 0;
        transition: max-height 0.3s ease;
        overflow: hidden; // da scroll -> hidden

        scroll-behavior: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
</style>