<template>
    <div class="oid-callback--view">
        <section>
            <bxs-layout class="text-center">
                <h6>openID callback route</h6>
                <p v-if="user">{{ user.data }}</p>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'oid_callback',
    data () {
        return {
            user: null
        }
    },
    mounted () {
        this.$nextTick(() => {
            console.log('OID Callback')
            console.log(this.$route.query)
            this.start()
        })
    },
    methods: {
        async start () {
            // { "code": "V8thXPxoLmrOsJWRWMjuF2cZqGDJdffHsTUQHBHh3etW0", "state": "QOA7qdVOzg" }
            const code = this.$route.query.code || 'V8thXPxoLmrOsJWRWMjuF2cZqGDJdffHsTUQHBHh3etW0'
            const state = this.$route.query.state || 'QOA7qdVOzg'

            const { data, access_token } = await this.$leila.post('/v1/auths/openID', {
                code, state
            })

            this.user = data

            this.$leila.auth.setAccessToken(access_token)

            await this.$leila.me.get()
            this.$leila.checkout.get()

            this.$router.push('/')
        }
    }
}
</script>