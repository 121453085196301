<template>
    <header :class="['bxs-navbar', {
        'bxs-navbar-mobile': !!$store.state.is_mobile
    }]">
        <bxs-layout fluid>
            <!-- desktop nav -->
            <div v-if="!$store.state.is_mobile">
                <bxs-row
                align="center"
                class="mb-1">
                    <bxs-col cols="2">
                        <logo
                        href="https://leila.srl"
                        target="_blank" />
                    </bxs-col>

                    <bxs-col cols="10">
                        <div class="flex nowrap align-center justify-end">
                            <!-- desktop inline nav -->
                            <ul class="flex nowrap align-center">
                                <li class="mr-6">
                                    <router-link
                                    to="/"
                                    class="text-no-deco">{{ $t('Home') }}</router-link>
                                </li>
                                <li class="mr-6">
                                    <router-link
                                    to="/catalogs/all"
                                    class="text-no-deco">{{ $t('Tutti gli oggetti') }}</router-link>
                                </li>
                                <li class="mr-6">
                                    <router-link
                                    to="/search"
                                    class="text-no-deco">{{ $t('Cerca per categoria') }}</router-link>
                                </li>
                                <!--  -->
                                <li
                                v-if="$store.state.library.links && $store.state.library.links.how_work"
                                class="mr-6">
                                    <a
                                    :href="$store.state.library.links.how_work"
                                    target="_blank"
                                    class="text-no-deco">{{ $t('Come funziona') }}</a>
                                </li>
                                <li
                                v-if="$store.state.library.regolamento"
                                class="mr-6">
                                    <router-link
                                    to="/regolamento"
                                    class="text-no-deco">{{ $t('Regolamento') }}</router-link>
                                </li>
                                <li class="mr-6">
                                    <router-link
                                    to="/help"
                                    class="text-no-deco">{{ $t('Supporto') }}</router-link>
                                </li>
                                <li
                                v-if="$store.state.library.faq_pdf_url"
                                class="mr-6">
                                    <a
                                    role="button"
                                    class="text-no-deco"
                                    @click="toPDFfaq">F.A.Q.</a>
                                </li>
                                <!--  -->
                                <li class="mr-3">
                                    <!-- <router-link
                                    v-if="!$leila.auth.me"
                                    to="/signin"
                                    class="text-no-deco">{{ $t('Accedi') }}</router-link> -->

                                    <!-- <router-link
                                    v-else
                                    to="/me"
                                    class="text-no-deco">{{ $t('Profilo') }}</router-link> -->

                                    <bxs-btn
                                    fab
                                    :light="!!$leila.auth.me"
                                    :color="!!$leila.auth.me ? 'black' : null"
                                    to="/me">
                                        <bxs-icon :name="!$leila.auth.me ? 'person' : 'person-fill'"></bxs-icon>
                                    </bxs-btn>
                                </li>
                                <li class="mr-3">
                                    <bxs-menu :title="$t('Seleziona la lingua')">
                                        <template #activator="{ on }">
                                            <bxs-btn v-on="on" fab>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16">
                                                    <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z"/>
                                                    <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31"/>
                                                </svg>
                                            </bxs-btn>
                                        </template>

                                        <bxs-list>
                                            <bxs-list-item
                                            :disabled="$i18n.locale === 'it'"
                                            spacer
                                            :append-icon="$i18n.locale === 'it' ? 'check-cirlce-fill' : null"
                                            @click="setLang('it')">
                                                <img
                                                src="/img/italy-flag-round.png"
                                                alt="bandiera italiana"
                                                style="max-width: 20px;"
                                                class="mr-ui">
                                                <span>{{ $t('Italiano') }}</span>
                                            </bxs-list-item>

                                            <bxs-list-item
                                            :disabled="$i18n.locale === 'en'"
                                            spacer
                                            :append-icon="$i18n.locale === 'en' ? 'check-cirlce-fill' : null"
                                            @click="setLang('en')">
                                                <img
                                                src="/img/united-kingdom-flag-round.png"
                                                alt="bandiera italiana"
                                                style="max-width: 20px;"
                                                class="mr-ui">
                                                <span>{{ $t('Inglese') }}</span>
                                            </bxs-list-item>
                                        </bxs-list>
                                    </bxs-menu>
                                </li>

                                <!-- <li class="mr-6">
                                    <a
                                    role="button"
                                    class="text-no-deco mr-ui">
                                        <img
                                        src="/img/italy-flag-round.png"
                                        alt="bandiera italiana"
                                        style="max-width: 25px; margin-top: 2px;"
                                        @click="setLang('it')">
                                    </a>

                                    <a
                                    role="button"
                                    class="text-no-deco">
                                        <img
                                        src="/img/united-kingdom-flag-round.png"
                                        alt="bandiera inglese"
                                        style="max-width: 25px; margin-top: 2px;"
                                        @click="setLang('en')">
                                    </a>
                                </li> -->
                            </ul>

                            <!-- desktop drawer nav -->
                            <bxs-menu
                            activator-icon="three-dots-vertical"
                            close-on-click>
                                <bxs-list class="mb-ui">
                                    <bxs-list-item to="/">{{ $t('Home') }}</bxs-list-item>

                                    <bxs-list-item to="/catalogs/all">{{ $t('Tutti gli oggetti') }}</bxs-list-item>

                                    <bxs-list-item to="/search">{{ $t('Cerca per categoria') }}</bxs-list-item>

                                    <hr>

                                    <bxs-list-item
                                    v-if="$store.state.library.links && $store.state.library.links.how_work && $store.state.library.links.how_work.length > 0"
                                    :href="$store.state.library.links.how_work"
                                    target="_blank">{{ $t('Come funziona') }}</bxs-list-item>

                                    <bxs-list-item
                                    v-if="$store.state.library.regolamento"
                                    to="/regolamento">{{ $t('Regolamento') }}</bxs-list-item>

                                    <bxs-list-item
                                    to="/help"
                                    class="mb-0">{{ $t('Supporto') }}</bxs-list-item>

                                    <bxs-list-item
                                    v-if="$store.state.library.faq_pdf_url"
                                    @click="toPDFfaq">F.A.Q.</bxs-list-item>

                                    <hr>

                                    <bxs-list-item
                                    v-if="!$leila.auth.me"
                                    to="/signin">{{ $t('Accedi') }}</bxs-list-item>

                                    <span v-if="!!$leila.auth.me">
                                        <bxs-list-item to="/me">{{ $t('Il mio profilo') }}</bxs-list-item>

                                        <bxs-list-item to="/me/orders">{{ $t('I miei prestiti') }}</bxs-list-item>
                                    </span>

                                    <hr>

                                    <bxs-list-item to="/scan">{{ $t('Scan') }}</bxs-list-item>

                                    <bxs-list-item
                                    v-if="$store.state.library && $store.state.library.website"
                                    :href="$store.state.library.website">{{ $t('Visita il nostro sito') }}</bxs-list-item>

                                    <hr>

                                    <bxs-list-item
                                    v-if="!!$leila.auth.me"
                                    prepend-icon="arrow-left"
                                    @click="signout">{{ $t('Esci') }}</bxs-list-item>
                                </bxs-list>

                                <bxs-footer class="mt-12 px-ui" />
                            </bxs-menu>

                            <bxs-chip
                            v-if="$store.state.ghost_user"
                            class="mr-ui">
                                <bxs-icon
                                name="person-fill"
                                class="text-success mr-2"></bxs-icon>
                                <small style="font-size: 10px;">{{ $store.state.ghost_user }}</small>
                            </bxs-chip>
                        </div>
                    </bxs-col>
                </bxs-row>

                <hr>
            </div>

            <!-- app nav -->
            <div v-else>
                <!-- navbar -->
                <bxs-toolbar
                flat
                light
                height="55">
                    <a
                    href="https://leila.srl"
                    target="_blank">
                        <img
                        src="/img/logo-typo-srl-light.png"
                        alt="Leila Italia Logo"
                        style="max-width: 50px;">
                    </a>

                    <bxs-spacer></bxs-spacer>

                    <bxs-btn
                    icon
                    fab
                    to="/">
                        <bxs-icon :name="$route.name === 'home' ? 'house-fill' : 'house'"></bxs-icon>
                    </bxs-btn>

                    <bxs-spacer></bxs-spacer>

                    <bxs-btn
                    icon
                    :color="$route.name === 'search' ? 'white' : null"
                    fab
                    to="/search">
                        <bxs-icon name="search"></bxs-icon>
                    </bxs-btn>

                    <bxs-spacer></bxs-spacer>

                    <bxs-btn
                    icon
                    fab
                    to="/help">
                        <bxs-icon viewbox="0 0 22 22">
                            <path
                            v-if="$route.name === 'help'"
                            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />

                            <g v-else>
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </g>
                        </bxs-icon>
                    </bxs-btn>

                    <bxs-spacer></bxs-spacer>

                    <bxs-btn
                    icon
                    fab
                    :color="$route.name === 'me' || $route.name === 'signin' ? 'white' : ''"
                    to="/me">
                        <bxs-icon :name="$route.name === 'me' || $route.name === 'signin' ? 'person-fill' : 'person'"></bxs-icon>
                    </bxs-btn>

                    <bxs-spacer></bxs-spacer>

                    <bxs-menu
                    :title="$t('Seleziona la lingua')"
                    close-on-click>
                        <template #activator="{ on }">
                            <bxs-btn
                            v-on="on"
                            icon
                            fab>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16">
                                    <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z"/>
                                    <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31"/>
                                </svg>
                            </bxs-btn>
                        </template>

                        <bxs-list>
                            <bxs-list-item
                            :disabled="$i18n.locale === 'it'"
                            spacer
                            :append-icon="$i18n.locale === 'it' ? 'check-cirlce-fill' : null"
                            @click="setLang('it')">
                                <img
                                src="/img/italy-flag-round.png"
                                alt="bandiera italiana"
                                style="max-width: 20px;"
                                class="mr-ui">
                                <span>{{ $t('Italiano') }}</span>
                            </bxs-list-item>

                            <bxs-list-item
                            :disabled="$i18n.locale === 'en'"
                            spacer
                            :append-icon="$i18n.locale === 'en' ? 'check-cirlce-fill' : null"
                            @click="setLang('en')">
                                <img
                                src="/img/united-kingdom-flag-round.png"
                                alt="bandiera italiana"
                                style="max-width: 20px;"
                                class="mr-ui">
                                <span>{{ $t('Inglese') }}</span>
                            </bxs-list-item>
                        </bxs-list>
                    </bxs-menu>

                    <bxs-spacer></bxs-spacer>

                    <bxs-menu title="Menu">
                        <template #activator="{ on }">
                            <bxs-btn
                            v-on="on"
                            icon>
                                <bxs-icon name="three-dots-vertical"></bxs-icon>
                            </bxs-btn>
                        </template>

                        <bxs-list>
                            <bxs-list-item to="/catalogs/all">{{ $t('Tutti gli oggetti') }}</bxs-list-item>

                            <bxs-list-item
                            v-if="$store.state.library.links && $store.state.library.links.how_work"
                            :href="$store.state.library.links.how_work"
                            target="_blank"
                            class="text-no-deco">{{ $t('Come funziona') }}</bxs-list-item>

                            <bxs-list-item
                            v-if="$store.state.library.regolamento"
                            to="/regolamento"
                            class="text-no-deco">{{ $t('Regolamento') }}</bxs-list-item>

                            <bxs-list-item
                            to="/help"
                            class="text-no-deco">{{ $t('Supporto') }}</bxs-list-item>

                            <bxs-list-item
                            v-if="$store.state.library.faq_pdf_url"
                            class="text-no-deco"
                            @click="toPDFfaq">F.A.Q.</bxs-list-item>

                            <bxs-list-item
                            v-if="!$leila.auth.me"
                            to="/signin"
                            class="text-no-deco">{{ $t('Accedi') }}</bxs-list-item>
                            <bxs-list-item
                            v-else
                            to="/me"
                            class="text-no-deco">{{ $t('Il mio profilo') }}</bxs-list-item>

                            <bxs-list-item to="/scan">{{ $t('Scan') }}</bxs-list-item>

                            <bxs-list-item
                            v-if="$store.state.library && $store.state.library.website"
                            :href="$store.state.library.website">{{ $t('Visita il nostro sito') }}</bxs-list-item>

                            <bxs-list-item
                            v-if="!!$leila.auth.me"
                            prepend-icon="arrow-left"
                            @click="signout">{{ $t('Esci') }}</bxs-list-item>
                        </bxs-list>
                    </bxs-menu>
                </bxs-toolbar>
            </div>
        </bxs-layout>
    </header>
</template>

<script>
export default {
    name: 'bxs-navbar',
    props: {
        access: {
            type: [String, Object, Boolean],
            required: false,
            default: null
        }
    },
    methods: {
        async signout () {
            await this.$leila.auth.signout()
            sessionStorage.removeItem('lel_ghost_user')
            this.$router.go()
        },
        share (social) {
            const left = (screen.width - 570) / 2
            const top = (screen.height - 570) / 2
            const params = 'menubar=no,toolbar=no,status=no,width=570,height=570,top=' + top + ',left=' + left
            let url = null

            if (social === 'tw') {
                url = `https://twitter.com/share?text=${document.title} | Abbiamo bisogno di utilizzare, non di condividere&url=${window.location.href}`
            } else if (social === 'fb') {
                url = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
            } else if (social === 'whatsapp') {
                url = `whatsapp://send?text=${document.title} | Abbiamo bisogno di utilizzare, non di condividere. ${window.location.href}`
            }

            // if (this.$store.state.is_touch_device) window.open(url, '_blank')
            window.open(url, 'TwitterWindow', params)
        },
        setLang (val) {
            this.$store.commit('set_is_loading', true)

            setTimeout(() => {
                localStorage.setItem('lel-lang', val)
                this.$i18n.locale = val
            }, 500)

            setTimeout(() => {
                this.$store.commit('set_is_loading', false)
            }, 1000)
        },
        toPDFfaq () {
            this.$store.commit('set_is_loading', true)
            this.$leila.streamPublicFile({
                filename: this.$store.state.library.faq_pdf_url,
                report: 'Files_della_biblioteca',
                report_record_id: this.$store.state.library.id,
                field_name: 'Faq_pdf1',
                field_action: 'download-file',
                report_auth_code: 'QSwHpVhbAew3JVEeT92e02GbKe0O2taFt1xxhPmadP45G9t3eMgauWPBDmU3Y7DSBaV9DtY0HsAA6NOOd6x2ukFpZOn4QRA88V5S'
            }, () => {
                this.$store.commit('set_is_loading', false)
            }, () => {
                this.$store.commit('set_is_loading', false)
                this.$toast.success('Ops, qualcosa è andato storto, riprova più tardi')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.bxs-navbar {
    position: relative;
    width: 100%;
    min-height: var(--navbar-height);
    background-color: var(--color-background);
    display: flex;
    align-items: center;
    border-top-left-radius: calc(var(--radius) * 2);
    border-top-right-radius: calc(var(--radius) * 2);

    &.bxs-navbar-mobile {
        position: fixed;
        width: 100%;
        min-height: var(--toolbar-height);
        bottom: 0; // var(--size-layout);
        left: 0;
        // background-color: transparent;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        z-index: var(--layer-navbar);
        background-color: var(--navbar-background-color);

        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: calc(100% + var(--navbar-height));
        //     background-image: var(--navbar-backdrop-gradient);
        //     z-index: -1;
        // }

        .bxs-layout {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
</style>