import Alert from './Alert.vue'
import Btn from './Btn.vue'
import BxsSwitchField from './form/BxsSwitchField.vue'
import BxsTextField from './form/TextField.vue'
import BxsTextareaField from './form/BxsTextareaField.vue'
import Card from './Card.vue'
import Carousel from './Carousel.vue'
import CategoriesSection from './project/CategoriesSection.vue'
import CheckoutBar from './project/CheckoutBar.vue'
import Chip from './Chip.vue'
import ChipGroup from './ChipGroup.vue'
import Col from './layout/Col/Col.vue'
import CollapseItem from './CollapseItem.vue'
import DataLazy from './DataLazy.vue'
import Figure from './media/FigureComp.vue'
import Footer from './layout/Footer.vue'
import Form from './form/Form'
import BxsFileUploader from './FileUploader.vue'
import BxsDrawer from './Drawer.vue'
import BxsModal from './Modal.vue'
// import Marquee from './Marquee.vue'

import HeadquarterRow from './project/HeadquarterRow.vue'
import HeadquartersSection from './project/HeadquartersSection.vue'
import HelpSection from './project/HelpSection.vue'
import Icon from './Icon.vue'
import Layout from './layout/Layout.vue'
import List from './List.vue'
import ListItem from './ListItem.vue'
import Loader from './Loader.vue'
import Logo from './Logo.vue'
import Menu from './Menu.vue'
import Stepper from './Stepper.vue'
import PressingSection from './project/PressingSection.vue'
import Row from './layout/Row/Row.vue'
import SigninSection from './project/SigninSection.vue'
import SocialsRow from './project/SocialsRow.vue'
import Spacer from './Spacer.vue'
import Toolbar from './Toolbar.vue'
import TopNavigation from './TopNavigation.vue'
import Categories2Section from './project/Categories2Section.vue'
import HighlightedProductsSection from './project/HighlightedProductsSection.vue'
import Title from './Title.vue'
import ToProductsSection from './project/ToProductsSection.vue'
import OrderRow from './project/OrderRow.vue'
import BottomNavigation from './BottomNavigation.vue'
import ProductRow from './project/ProductRow'

// libs
import Toast from 'vue-toastification'

import 'vue-toastification/dist/index.css'

export default function createComp(app) {
    app.component('bxs-btn', Btn)
    app.component('bxs-card', Card)
    app.component('bxs-alert', Alert)
    app.component('bxs-carousel', Carousel)
    app.component('bxs-stepper', Stepper)
    app.component('bxs-chip', Chip)
    app.component('bxs-chip-group', ChipGroup)
    app.component('bxs-col', Col)
    app.component('bxs-collapse-item', CollapseItem)
    app.component('bxs-file-uploader', BxsFileUploader)
    app.component('bxs-data-lazy', DataLazy)
    app.component('bxs-figure', Figure)
    app.component('bxs-footer', Footer)
    app.component('bxs-form', Form)
    app.component('bxs-icon', Icon)
    app.component('bxs-layout', Layout)
    app.component('bxs-list', List)
    app.component('bxs-list-item', ListItem)
    app.component('bxs-loader', Loader)
    app.component('bxs-menu', Menu)
    app.component('bxs-row', Row)
    app.component('bxs-spacer', Spacer)
    app.component('bxs-switch-field', BxsSwitchField)
    app.component('bxs-text-field', BxsTextField)
    app.component('bxs-textarea-field', BxsTextareaField)
    app.component('bxs-toolbar', Toolbar)
    app.component('bxs-top-nav', TopNavigation)
    app.component('bxs-drawer', BxsDrawer)
    app.component('bxs-modal', BxsModal)
    // app.component('bxs-marquee', Marquee)

    app.component('categories-section', CategoriesSection)
    app.component('categories-2-section', Categories2Section)
    app.component('checkoutbar', CheckoutBar)
    app.component('headquarter-row', HeadquarterRow)
    app.component('headquarters-section', HeadquartersSection)
    app.component('help-section', HelpSection)
    app.component('logo', Logo)
    app.component('pressing-section', PressingSection)
    app.component('signin-section', SigninSection)
    app.component('socials-row', SocialsRow)
    app.component('bxs-title', Title)
    app.component('highlighted-products-section', HighlightedProductsSection)
    app.component('to-products-section', ToProductsSection)
    app.component('order-row', OrderRow)
    app.component('bxs-bottom-nav', BottomNavigation)
    app.component('product-row', ProductRow)

    app.use(Toast, {
        position: 'top-center',
        timeout: 4000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        newestOnTop: false,
        transition: 'Vue-Toastification__fade',
        bodyClassName: ['bxs-notify'],
        maxToasts: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        draggable: true
    })
}