<template>
    <footer class="text-center py-ui">
        <!-- <hr class="my-ui"> -->

        <logo
        href="https://leila.srl/"
        target="_blank"
        max-width="100px"
        class="mb-1" />

        <p class="text-mute"><small>Leila S.r.l. © 2019-{{ new Date().getFullYear() }}</small></p>

        <socials-row class="mt-ui" />

        <!-- <div class="mt-ui">
            <a
            role="button"
            class="text-no-deco mr-ui">
                <img
                src="/img/italy-flag-round.png"
                alt="bandiera italiana"
                style="max-width: 25px; margin-top: 2px;"
                @click="setLang('it')">
            </a>

            <a
            role="button"
            class="text-no-deco">
                <img
                src="/img/united-kingdom-flag-round.png"
                alt="bandiera inglese"
                style="max-width: 25px; margin-top: 2px;"
                @click="setLang('en')">
            </a>
        </div> -->
    </footer>
</template>

<script>
export default {
    name: 'bxs-footer',
    methods: {
        setLang (val) {
            this.$store.commit('set_is_loading', true)

            setTimeout(() => {
                localStorage.setItem('lel-lang', val)
                this.$i18n.locale = val
            }, 500)

            setTimeout(() => {
                this.$store.commit('set_is_loading', false)
            }, 1000)
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    position: relative;
    width: 100%;
    min-height: var(--footer-height);
    background-color: var(--footer-background-color);
    color: var(--footer-color);
    padding-left: var(--footer-padding-x);
    padding-right: var(--footer-padding-x);

    // display: flex;
    // flex-flow: row nowrap;
    // align-items: center;
    // justify-content: space-between;
}
</style>