<template>
    <div class="checkout-recap-view">
        <bxs-top-nav :sticky="false">
            <span>{{ $t('Recap prestito') }}</span>

            <template #append>
                <bxs-btn
                v-if="!$store.state.is_mobile && $leila.checkout.shipping.method !== 'delivery'"
                :loading="loading"
                :disabled="loading"
                uppercase
                color="primary"
                light
                @click="confirm"><span class="text-500 small">{{ $t('Conferma e invia prenotazione') }}</span></bxs-btn>
            </template>
        </bxs-top-nav>

        <section v-if="loading || error">
            <bxs-layout>
                <bxs-loader v-if="loading"></bxs-loader>

                <bxs-alert
                v-if="error"
                type="error">{{ error }}</bxs-alert>
            </bxs-layout>
        </section>

        <bxs-data-lazy
        v-model="data_page"
        :call="() => $leila.checkout.get()">
            <section>
                <bxs-layout>
                    <bxs-title class="mb-ui">{{ $t('Oggetto') }}</bxs-title>

                    <bxs-card class="pa-ui">
                        <product-row
                        :item="data_page.product"
                        hide-actions
                        style="pointer-events: none;" />
                    </bxs-card>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div class="mb-ui">
                        <bxs-title>{{ $t('Periodo di prestito') }}</bxs-title>
                        <p v-if="$leila.checkout.shipping.address.id !== 'delivery'">{{ $t('Vieni a ritirare il tuo oggetto a partire') }} <b> {{ $t('dal') }} {{ $filters.toHumanDate($leila.checkout.loan.from_date, 'L') }}</b></p>
                    </div>

                    <div
                    v-if="$leila.checkout.shipping.address.id !== 'delivery'"
                    class="flex align-center">
                        <bxs-chip
                        width="100%"
                        tile>{{ $filters.toHumanDate($leila.checkout.loan.from_date) }}</bxs-chip>

                        <bxs-spacer></bxs-spacer>

                        <bxs-icon
                        width="60"
                        name="arrow-right"
                        class="mx-6"></bxs-icon>

                        <bxs-spacer></bxs-spacer>

                        <bxs-chip
                        width="100%"
                        tile>{{ $filters.toHumanDate($leila.checkout.loan.to_date) }}</bxs-chip>
                    </div>

                    <div v-else>
                        <bxs-alert>
                            <p class="mb-1">{{ $t('Direttamente in') }} <i>{{ $leila.checkout.shipping.address.address }}, {{ $leila.checkout.shipping.address.cap }} {{ $leila.checkout.shipping.address.city }} {{ $leila.checkout.shipping.address.province }}</i> {{ $t('con un contribuito di') }} {{ $store.state.library.delivery.price }} €</p>
                            <p class="mb-ui">{{ $t('Il periodo di prestito verrà calcolato e concordato in base alla data della consegna.') }}</p>
                            <p class="h6">{{ $t('Ricordati, hai 10 minuti per concludere l\'ordine con PayPal.') }}</p>
                            <!-- <p>Conferma la prenotazione per essere reindirizzat* sulla pagina PayPal.</p> -->
                            <!-- <h1 class="mt-ui">{{ timerCount }}</h1> -->
                        </bxs-alert>
                    </div>

                    <!-- <bxs-card class="pa-ui">
                        <div class="flex mb-1">
                            <p>Dal</p>
                            <bxs-spacer></bxs-spacer>
                            <p><b>{{ $filters.toHumanDate(data_page.loan.from_date, 'L') }}</b></p>
                        </div>

                        <div class="flex">
                            <p>Al</p>
                            <bxs-spacer></bxs-spacer>
                            <p><b>{{ $filters.toHumanDate(data_page.loan.to_date, 'L') }}</b></p>
                        </div>
                    </bxs-card> -->
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div v-if="$leila.checkout.shipping.address.id !== 'delivery'">
                        <div class="mb-ui">
                            <bxs-title>{{ $t('Punto di ritiro') }}</bxs-title>
                            <p>{{ $t('L\'oggetto dovrà essere restituito presso il corner dov\'è stato ritirato, se hai necessità diverse') }} <router-link to="/help">{{ $t('contattaci') }}</router-link>.</p>
                        </div>

                        <bxs-card class="pa-ui">
                            <headquarter-row
                            v-if="$leila.checkout.shipping.address.id !== 'delivery'"
                            :item="data_page.shipping.address" />
                        </bxs-card>
                    </div>

                    <div
                    v-else
                    class="mb-ui">
                        <div class="mb-ui">
                            <bxs-title>{{ $t('Punto di riconsegna') }}</bxs-title>
                            <p>{{ $t('L\'oggetto dovrà essere restituito presso il corner di appartenenza o concordato, se hai necessità diverse,') }} <router-link to="/help">{{ $t('contattaci') }}</router-link>.</p>
                        </div>

                        <bxs-card class="pa-ui">
                            <headquarter-row :item="data_page.product.location.address" />
                        </bxs-card>
                    </div>
                </bxs-layout>
            </section>

            <section v-if="data_page.note">
                <bxs-layout>
                    <bxs-title>{{ $t('Note') }}</bxs-title>

                    <bxs-card class="pa-ui">
                        <div v-html="$filters.mdToHtml(data_page.note)"></div>
                    </bxs-card>
                </bxs-layout>
            </section>

            <section v-if="$store.state.library.is.renewable_card">
                <bxs-layout>
                    <bxs-title class="mb-ui">Tessera scaduta?</bxs-title>

                    <bxs-btn
                    append-icon="chevron-right"
                    between
                    block
                    outlined
                    uppercase
                    min-height="60px"
                    class="mb-ui"
                    @click="$store.commit('set_drawers', 'renew')">{{ $t('Rinnova la tua tessera') }}</bxs-btn>
                </bxs-layout>
            </section>

            <section class="text-center">
                <bxs-layout>
                    <small>{{ $t('Ovviamente non c\'è bisogno di attendere la scadenza del prestito') }} <br v-if="!$store.state.is_mobile"> {{ $t('per restituire l\'oggetto, gli altri soci non potranno che esserne felici.') }}</small>
                </bxs-layout>
            </section>
        </bxs-data-lazy>

        <bxs-bottom-nav
        color="trasparent"
        :bottom="$store.state.is_mobile ? 60 : 0">
            <bxs-btn
            v-if="$leila.checkout.shipping.method !== 'delivery'"
            min-height="50"
            block
            max-width="320"
            :loading="loading"
            :disabled="loading"
            uppercase
            color="primary"
            light
            class="mx-auto"
            @click="confirm">
                <span class="text-500">{{ $t('Conferma e invia prenotazione') }}</span>
            </bxs-btn>

            <div
            v-else
            id="paypal-button-container"
            style="width: 100%;"></div>
        </bxs-bottom-nav>
    </div>
</template>

<script>
import { mapState } from 'vuex'

// "buyer": {
//     "email": "sb-uxcap26022810_api1.business.example.com",
//     "password": "325PEPJYCM44YLR7"
// },
// "customer": {
//     "email": "sb-rnvio26284664@personal.example.com",
//     "password": "+W)ZL0}v"
// }

export default {
    name: 'checkout_recap',
    computed: {
        ...mapState({
            checkout: state => state.checkout
        })
    },
    data () {
        return {
            data_page: null,
            loading: true,
            error: null,
            timeout: null
            // timerEnabled: true
            // timerCount: 600000
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    // watch: {
    //     timerEnabled (value) {
    //         if (value) {
    //             setTimeout(() => {
    //                 this.timerCount--
    //             }, 1000)
    //         }
    //     },
    //     timerCount: {
    //         handler(value) {
    //             if (value > 0 && this.timerEnabled) {
    //                 setTimeout(() => {
    //                     this.timerCount--
    //                 }, 1000)
    //             }
    //         },
    //         immediate: true // This ensures the watcher is triggered upon creation
    //     }
    // },
    methods: {
        start () {
            if (this.$leila.checkout.shipping.method === 'delivery') {
                this.$leila.post('v1/checkout/intent').then(() => {
                    this.paypal().load().then(() => {
                        this.paypal().init()
                        this.createTimeout()
                    })

                    this.loading = false
                }).catch((err) => {
                    this.error = err
                    this.loading = false
                })
            } else {
                this.loading = false
            }
        },
        createTimeout () {
            this.clearTimeout()

            this.timeout = setTimeout(() => {
                // this.$leila.checkout.clear()
                this.$toast.success('Tempo scaduto, prestito annullato!')
                this.$router.push('/products/' + this.data_page.product.id)
                clearTimeout(this.timeout)
            }, 600000) // 600000 10m

            return this.timeout
        },
        clearTimeout () {
            if (this.timeout) clearTimeout(this.timeout)
        },
        paypal () {
            return {
                load: () => {
                    console.log('load paypal')

                    return new Promise((resolve, reject) => {
                        const script = document.createElement('script')
                        script.setAttribute('data-sdk-integration-source', 'button-factory')
                        const pk = this.$store.state.library.pp_pk // 'AcvcIXorz3s7nW2cE_BnIoiHbPbLzy-jbfvwFOXjTqJ0HQUS7PyzhzqNhsWpnMB6IBcI9ZkhVAZrVbwI'
                        // const query_components = '&components=buttons'
                        const query = '?client-id=' + pk + '&currency=EUR&disable-funding=card,credit,bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo'
                        script.src = 'https://www.paypal.com/sdk/js' + query
                        // data-sdk-integration-source="button-factory"
                        // script.setAttribute('data-sdk-integration-source', 'button-factory')
                        script.addEventListener('load', resolve)
                        document.body.appendChild(script)
                    })
                },
                init: () => {
                    // eslint-disable-next-line
                    return paypal.Buttons({
                        style: {
                            shape: 'rect',
                            color: 'blue',
                            layout: 'vertical',
                            label: 'paypal'
                        },
                        onClick: async (data, actions) => {
                            this.$store.commit('set_is_loading', true)
                            console.log('paypal onClick()', data)
                            this.error = null

                            try {
                                await this.$leila.post('v1/checkout/intent')
                            } catch (err) {
                                this.$store.commit('set_is_loading', false)
                                console.log('paypal onClick() error', err)
                                this.error = err
                                return actions.reject()
                            }
                        },
                        createOrder: (data, actions) => {
                            console.log('paypal createOrder()', data)

                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            currency_code: 'EUR',
                                            value: this.$store.state.library.delivery.price
                                        }
                                    }
                                ]
                            })
                        },
                        onCancel: (data, actions) => {
                            console.log('paypal onCancel()', data)
                            this.$store.commit('set_is_loading', false)
                        },
                        onApprove: (data, actions) => {
                            console.log('paypal onApprove()', data, actions)

                            return actions.order.capture().then((d) => {
                                console.log('paypal onApprove().order.capture()', d)
                                this.confirm(data)
                            }).catch((err) => {
                                this.error = err
                                console.log('paypal onApprove() order.capture() error', err)
                            })
                        },
                        //
                        payment: (data, actions) => {
                            console.log('paypal payment()', data)
                        },
                        onAuthorize: (data, actions) => {
                            console.log('paypal onAuthorize()', data)
                        },
                        onError: (err) => {
                            this.$store.commit('set_is_loading', false)
                            this.error = err
                            this.createTimeout()
                            console.log('paypal onError()', err)
                        }
                    }).render('#paypal-button-container')
                }
            }
        },
        async confirm (pp) {
            this.error = null
            this.loading = true
            this.$store.commit('set_is_loading', true)

            try {
                const { id } = await this.$leila.checkout.confirm({ paypal: pp })
                this.$leila.checkout.get()
                this.clearTimeout()
                this.$router.push('/checkout/' + id + '/cb')
            } catch (err) {
                this.error = err
                throw new Error(err)
                // this.error = err.message || err.name || err
            } finally {
                this.loading = false
                this.$store.commit('set_is_loading', false)
            }
        }
    },
    beforeUnmount () {
        this.clearTimeout()
    }
}
</script>