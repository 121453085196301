<template>
    <div class="bxs-modal">
        <div
        v-if="$slots.activator"
        class="bxs-modal--activator">
            <slot
            name="activator"
            :on="{
                click: () => local_on = !local_on
            }"
            :attrs="{
                role: 'button',
                'aria-haspopup': true,
                'aria-expanded': false
            }" />
        </div>

        <!--  -->
        <transition
        @enter="enter"
        @leave="leave"
        mode="out-in"
        :css="false">
            <div
            v-if="local_on"
            :class="['bxs-modal--modal', {
                'bxs-modal--modal-full': full
            }]"
            :style="modal_styles"
            @click.self="_closeFromBg">
                <bxs-layout
                ref="container"
                fluid
                :max-width="maxWidth"
                class="bxs-modal--modal--container">
                    <!-- header -->
                    <div
                    class="bxs-modal--modal--container--header"
                    :style="color ? { backgroundColor: color } : {}">
                        <div class="bxs-modal--modal--container--header--inner pa-ui">
                            <div class="flex-1">
                                <slot name="header" />
                            </div>

                            <bxs-icon
                            v-if="!hideClose"
                            name="close"
                            @click="close" />
                        </div>

                        <slot name="subheader" />
                    </div>

                    <!-- body -->
                    <div
                    class="bxs-modal--modal--container--body"
                    :style="{
                        backgroundColor: color,
                        backgroundImage: `url(${src})`
                    }">
                        <div class="bxs-overlay-2"></div>
                        <slot />
                    </div>

                    <!-- actions -->
                    <div
                    v-if="!!$slots.actions"
                    class="bxs-modal--modal--container--actions"
                    :style="{ backgroundColor: color }">
                        <slot
                        name="actions"
                        :on="{
                            click: () => local_on = !local_on
                        }"
                        :attrs="{
                            role: 'button',
                            'aria-haspopup': true,
                            'aria-expanded': false
                        }" />
                    </div>
                </bxs-layout>
            </div>
        </transition>
    </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
    name: 'modal',
    props: {
        modelValue: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        'max-width': {
            type: [String, Number],
            required: false,
            default: 512
        },
        persistent: {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-close': {
            type: Boolean,
            required: false,
            default: false
        },
        'overlay-opacity': {
            type: [String, Number],
            required: false,
            default: 0.8
        },
        'z-index': {
            type: [String, Number],
            required: false,
            default: null
        },
        color: {
            type: String,
            required: false,
            default: null
        },
        src: {
            type: String,
            required: false,
            default: null
        },
        full: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:modelValue',
        'change',
        'close'
    ],
    computed: {
        modal_styles () {
            const v = {
                backgroundColor: `rgba(0, 0, 0, ${this.overlayOpacity})`
            }

            if (this.zIndex) v.zIndex = this.zIndex

            return v
        }
    },
    data () {
        return {
            local_on: this.modelValue || this.value
        }
    },
    watch: {
        modelValue (newVal) {
            this.local_on = newVal
        },
        local_on (newVal) {
            if (newVal) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = ''
            }
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.local_on = this.modelValue

            if (this.local_on) {
                document.body.style.overflow = 'hidden'
            }
        })
    },
    methods: {
        _closeFromBg () {
            if (this.persistent) return
            this.close()
        },
        close () {
            this.local_on = false
            this.$emit('update:modelValue', this.local_on)
        },
        enter (el, next) {
            this.$nextTick(() => {
                // gsap.to(el, {
                //     backgroundColor: 'rgba(0, 0, 0, 0.65)',
                //     duration: 0.5,
                //     ease: 'expo.out'
                // })

                gsap.fromTo(el, {
                    y: -20,
                    autoAlpha: 0
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.75,
                    ease: 'expo.out',
                    onComplete: () => next()
                })
            })
        },
        leave (el, next) {
            gsap.to(el, {
                y: 20,
                autoAlpha: 0,
                duration: 0.5,
                ease: 'expo.out',
                onComplete: () => next()
            })
        }
    },
    beforeUnmount() {
        document.body.style.overflow = ''
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

.bxs-modal {
    &--activator {
        position: relative;
    }

    &--modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);

        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        padding: calc(var(--modal-padding) * 2) var(--modal-padding);
        z-index: var(--layer-modal);

        &.bxs-modal--modal-full {
            .bxs-modal--modal--container {
                height: 100vh;
            }
        }

        &--container {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-height: 35vh;
            max-height: 100vh; // limita l’altezza del modale alla viewport
            overflow: hidden;     // importante per evitare scroll extra

            padding-left: 0;
            padding-right: 0;
            -webkit-box-shadow: 0px 0px 15px 0px rgba(#000, 0.3);
            box-shadow: 0px 0px 15px 0px rgba(#000, 0.3);

            &--header {
                flex-shrink: 0;
                position: sticky;
                top: 0;
                z-index: 2;
                height: var(--list-item-min-height);

                background-color: var(--color-background);
                border-radius: var(--modal-border-radius);
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom: 1px solid var(--divider-color);

                &--inner {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                }
            }

            &--body {
                flex: 1 1 auto;
                overflow-y: auto;
                overflow-x: hidden;
                min-height: 0;

                background-color: var(--color-background);
                z-index: 1;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;

                // border: 4px dashed red;
            }

            &--actions {
                flex-shrink: 0;
                position: sticky;
                z-index: 2;
                bottom: 0;
                height: var(--list-item-min-height);

                // position: relative;
                // margin-top: auto;
                background-color: var(--color-background);
                border-top: 1px solid var(--divider-color);
                z-index: 2;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}

.bxs-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0; // ✅ questo fa la magia
  pointer-events: none;
  z-index: 0;
  transform: scale(1.1);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; // ✅
    background-image: linear-gradient(to right, rgba(#000, 1), rgba(#bd1823, 0.5));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}
</style>