<template>
    <Teleport to="body">
        <div
        v-if="$leila.auth.me && !!$leila.checkout.exists"
        class="checkout-bar bxs-no-select">
            <bxs-toolbar
            light
            color="black"
            :max-width="!$store.state.is_mobile ? 968 : '97%'"
            class="px-3 bxs-material">
                <div
                v-if="!!$leila.checkout.product"
                class="flex nowrap align-center flex-1 py-3"
                style="width: 50%;">
                    <bxs-figure
                    ratio="1"
                    :src="$filters.toStaticFile($leila.checkout.product.img.url)"
                    max-width="40"
                    class="bxs-pointer"
                    @click="$router.push('/products/' + $leila.checkout.product.id).catch(() => {})" />

                    <div class="bxs-truncate px-3">
                        <p
                        v-html="$leila.checkout.product.name"
                        class="text-300"></p>

                        <p
                        v-if="!!$leila.checkout.loan.from_date && !!$leila.checkout.loan.to_date"
                        class="text-500">
                            <small>{{ $filters.toHumanDate($leila.checkout.loan.from_date, 'l') }}</small>
                            <bxs-icon name="arrow-right" class="mx-1"></bxs-icon>
                            <small>{{ $filters.toHumanDate($leila.checkout.loan.to_date, 'l') }}</small>
                            <!-- <small class="text-white ml-2">(giorni {{ $leila.checkout.loan.no_of_days }})</small> -->
                        </p>
                    </div>
                </div>

                <bxs-chip
                v-if="!$leila.checkout.exists"
                color="black"
                @click="$router.push('/me').catch(() => {})">{{ $leila.me.available_loans }} {{ $t('disponibili') }}</bxs-chip>

                <bxs-chip
                v-if="!$leila.checkout.exists"
                color="success"
                small
                class="ml-ui text-500 text-uppercase"
                @click="$router.push('/me').catch(() => {})">{{ $leila.me.name[0] }}</bxs-chip>

                <bxs-btn
                v-if="!!$leila.checkout.exists && !!$leila.checkout.product"
                :loading="loading"
                :disabled="disabled || loading"
                append-icon="chevron-right"
                uppercase
                text
                small
                @click="click">{{ $t('Continua') }}</bxs-btn>

                <bxs-menu
                v-if="!$store.state.is_mobile"
                icon>
                    <bxs-list>
                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        @click="click">{{ $t('Continua il checkout') }}</bxs-list-item>

                        <bxs-list-item
                        append-icon="trash"
                        spacer
                        @click="clearCart">
                            <span>{{ $t('Abbandona il checkout') }}</span>

                            <template #append-icon>
                                <path fill-rule="evenodd" d="M6.146 8.146a.5.5 0 0 1 .708 0L8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 0 1 0-.708"/>
                                <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
                            </template>
                        </bxs-list-item>
                    </bxs-list>
                </bxs-menu>

                <slot name="actions" />
            </bxs-toolbar>
        </div>
    </Teleport>
</template>

<script>
import Hammer from 'hammerjs'

export default {
    name: 'checkout-bar',
    props: {
        to: {
            type: [String, Object],
            required: false,
            default: null
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'click:next'
    ],
    data () {
        return {
            local_loading: false
        }
    },
    mounted () {
        this.$nextTick(this.play)
    },
    methods: {
        play () {
            if (this.$store.state.is_mobile) {
                const el = document.querySelector('.checkout-bar')
                const manager = new Hammer(el)
                const panner = new Hammer.Pan({
                    direction: Hammer.DIRECTION_LEFT,
                    threshold: 0
                })

                manager.add(panner)

                manager.on('panstart', (e) => {
                    // el.classList.remove('inner--transition')
                })

                manager.on('panmove', (e) => {
                    console.log('panmove', e)

                    el.style.transform = `translateX(${e.deltaX}px)`
                    el.style.opacity = 0.3
                })

                manager.on('panend', (e) => {
                    // console.log('panend', e)

                    if (e.center.x <= 0) {
                        this.local_loading = true
                        this.$leila.checkout.clear().then(() => {
                            this.local_loading = false

                            if (this.$route.name.includes('checkout_')) {
                                this.$router.go(-1)
                            }
                        }).catch(() => {
                            this.local_loading = false
                        })
                    } else {
                        el.style.transform = 'translateX(0px)'
                        el.style.opacity = 1
                    }
                })
            }
        },
        click () {
            if (this.to) this.$router.push(this.to).catch(() => {})
            this.$emit('click:next')
        },
        async clearCart () {
            this.$store.commit('set_is_loading', true)

            await this.$leila.checkout.clear()

            if (this.$route.name.includes('checkout_')) {
                this.$router.go(-1)
            }

            this.$store.commit('set_is_loading', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.checkout-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    padding-bottom: calc(var(--toolbar-height) + 12px);
    width: 100vw;
    z-index: var(--layer-drawer);
    transition: transform 0.05s ease, opacity 0.5s ease;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: -1;
    //     // border-top-left-radius: 20px;
    //     // border-top-right-radius: 20px;
    //     background-color: rgba(#000, 0.25);
    // }
}
</style>