import Resource from './BaseResource'

class Me extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)

        this.exists = false
    }

    async get() {
        const res = await this.tools.http.get(`${this.api}`)

        if (res) for (const k in res) this[k] = res[k]

        return res
    }

    async getProducts(q) {
        const res = await this.tools.http.get(`${this.api}/products`, q)
        return res
    }

    async getOrders(q) {
        const res = await this.tools.http.get(`${this.api}/orders`, q)
        return res
    }

    async getMemberships(q) {
        const res = await this.tools.http.get(`${this.api}/memberships`, q)
        return res
    }

    async getOrder(id, q) {
        const res = await this.tools.http.get(`${this.api}/orders/${id}`, q)
        return res
    }

    async extendOrder(id) {
        const res = await this.tools.http.post(`${this.api}/orders/${id}/extensions`)
        return res
    }

    cancelOrder(id) {
        return this.tools.http.delete(`${this.api}/orders/${id}`)
    }

    addProductToWish(id) {
        return this.tools.http.post(`${this.api}/products/${id}/wishes`)
    }

    createProduct(body) {
        return this.tools.http.post(`${this.api}/products`, body)
    }

    createProductReview(id, body) {
        return this.tools.http.post(`${this.api}/products/${id}/reviews`, body)
    }
}

export default Me
