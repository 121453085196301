<template>
    <div
    v-sticky="sticky"
    class="bxs-top-navigation">
        <bxs-toolbar color="background">
            <slot name="undo">
                <bxs-btn
                v-if="!hideUndo"
                icon
                color="black"
                @click="undo ? $router.push(undo) : has_history_undo ? $router.go(-1) : $router.push('/')">
                    <bxs-icon name="chevron-left"></bxs-icon>
                </bxs-btn>
            </slot>

            <div class="bxs-top-navigation--body bxs-truncate">
                <bxs-title
                :level="5"
                class="mb-0">
                    <slot />
                </bxs-title>
            </div>

            <slot name="append">
                <!-- <img
                :src="$filters.toStaticFile($store.state.library.logo)"
                :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} logo`"
                style="max-width: 60px;"> -->
            </slot>
        </bxs-toolbar>

        <slot name="append-outer" />
    </div>
</template>

<script>
export default {
    name: 'bxs-top-navigation',
    props: {
        undo: {
            type: String,
            required: false,
            default: null
        },
        'hide-undo': {
            type: Boolean,
            required: false,
            default: false
        },
        sticky: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data () {
        return {
            has_history_undo: false
        }
    },
    mounted () {
        this.$nextTick(() => {
            // console.log(this.$store.state.page_leave)
            // console.log(window.history)
            this.has_history_undo = !!this.$store.state.page_leave
            // console.log(this.has_history_undo)
        })
    }
}
</script>

<style lang="scss" scoped>
.bxs-top-navigation {
    position: relative;

    > .bxs-toolbar {
        justify-content: space-between;
    }

    &--body {
        position: absolute;
        width: calc(80% - var(--size-layout));
        flex: 1;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        pointer-events: none;
    }
}
</style>