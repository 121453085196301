/* eslint-disable */

export default {
    'Abbandona il checkout': 'Abandon checkout',
    'Abbandona questo prestito e riparti': 'Abandon this borrowed item and start over',
    'Abbandona questo prestito': 'Abandon this borrowed item',
    'Abbiamo inviato un\'email a': 'We have sent an email to',
    'Accedi con openID': 'Login with openID',
    'Accedi e prenota!': 'Login and book!',
    'Accedi': 'Login',
    'Ancora nessun oggetto': 'No items yet',
    'Annulla e chiudi': 'Cancel and close',
    'Annulla prestito': 'Cancel borrowed item',
    'Annullati': 'Cancelled',
    'Annullato': 'Cancelled',
    'Approvati': 'Approved',
    'Approvato': 'Approved',
    'Attesa conferma': 'Confirmation pending',
    'Attrezzi': 'Tools',
    'Avvisami quando rientra': 'Notify me when it returns',
    'Bimbi': 'Kids',
    'Bisogno di aiuto? Contattaci': 'Need help? Contact us',
    'Bisogno di aiuto?': 'Need help?',
    'Cambia password': 'Change password',
    'Campeggio': 'Camping',
    'Carica il tuo oggetto': 'Upload your item',
    'Carica il tuo oggetto': 'Upload your item',
    'Casa': 'Home',
    'Categorie': 'Categories',
    'Cerca gli oggetti': 'Search for items',
    'Cerca per categoria': 'Search items',
    'Checkout in corso': 'Checkout in progress',
    'Ci vogliono': 'It takes',
    'Ciao': 'Hello',
    'Codice deposito': 'Deposit code',
    'Codice ritiro': 'Pickup code',
    'Come funziona': 'How it works',
    'Condividere is the new possedere': 'Sharing is the new owning',
    'Condividere': 'Sharing',
    'Condivisi': 'Shared',
    'Conferma annulla prestito': 'Confirm borrowed item cancellation',
    'Conferma e invia prenotazione': 'Confirm and send booking',
    'Conferma': 'Confirm',
    'Consegna a domicilio': 'Home delivery',
    'Contatti e assistenza': 'Contacts and support',
    'Contatti': 'Contacts',
    'Continua con questo prestito': 'Continue',
    'Continua il checkout': 'Continue checkout',
    'Continua': 'Continue',
    'Cucina': 'Kitchen',
    'Data richiesta': 'Request date',
    'Descrizione ancora non disponibile': 'Description not yet available',
    'Descrizione': 'Description',
    'Direttamente a casa tua con un contribuito di': 'Directly to your home with a contribution of',
    'Direttamente a casa tua!': 'Directly to your home!',
    'Direttamente in': 'Directly in',
    'Disponibili': 'Available',
    'Domenica': 'Sunday',
    'Domicilio': 'Residence',
    'Email invitata': 'Email sent',
    'Esci': 'Logout',
    'Estendi prestito': 'Extend borrowed item',
    'Estesi': 'Extended',
    'Esteso': 'Extended',
    'Giardinaggio': 'Gardening',
    'Giochi': 'Games',
    'Giovedi': 'Thursday',
    'Grazie per la tua prenotazione!': 'Thank you for your booking!',
    'Grazie per la tua recensione. Buona condivisione!': 'Thank you for your review. Happy sharing!',
    'Hai bisogno di aiuto? Contattaci': 'Need help? Contact us',
    'Hai delle domande?': 'Do you have any questions?',
    'Hai già recensito questo oggetto!': 'You have already reviewed this item!',
    'Hai già un account? Accedi': 'Already have an account? Login',
    'Hai già un profilo? Accedi': 'Already have a profile? Login',
    'Hobby': 'Hobby',
    'Home': 'Home',
    'I miei oggetti': 'My items',
    'I miei prestiti': 'My borrowed items',
    'I miei tesseramenti': 'My memberships',
    'I tuoi oggetti': 'Your items',
    'I tuoi prestiti': 'Your borrowed items',
    'Il mio profilo': 'My profile',
    'Il periodo di prestito verrà calcolato e concordato in base alla data della consegna.': 'The borrowed item period will be calculated and agreed upon based on the delivery date.',
    'Il periodo di prestito verrà calcolato in base alla data della consegna': 'The borrowed item period will be calculated based on the delivery date',
    'Il tuo feedback': 'Your feedback',
    'Il tuo prestito': 'Your borrowed item',
    'Il tuo profilo': 'Your profile',
    'Impossibile trovare': 'Unable to find',
    'Imposta o recupera la password': 'Set or recover password',
    'In prestito': 'Borrowed',
    'In questa sezione troverai lo storico dei tuoi oggetti': 'In this section, you will find the history of your items',
    'Indirizzo di consegna': 'Delivery address',
    'Informazioni base': 'Basic information',
    'Inglese': 'English',
    'Inserisci il codice ricevuto via email': 'Enter the code received via email',
    'Inserisci qui le tue domande!': 'Enter your questions here!',
    'Invia recensione': 'Submit review',
    'Iscrizioni': 'Registrations',
    'Istruzioni smartlocker': 'Smart locker instructions',
    'Italiano': 'Italian',
    'L\'immagine ha solo lo scopo di rappresentare il prodotto, quello presente in biblioteca potrebbe essere differente sia nel colore che nel modello.': 'The image is for product representation purposes only; the actual item in the library may differ in color or model.',
    'L\'oggetto dovrà essere restituito presso il corner di appartenenza o concordato, se hai necessità diverse,': 'The item must be returned at the designated or agreed-upon corner, if you have different requirements,',
    'L\'oggetto dovrà essere restituito presso il corner di appartenenza o concordato, se hai necessità diverse,': 'The item must be returned to its designated or agreed pickup corner; if you have different needs,',
    'L\'oggetto dovrà essere restituito presso il corner dov\'è stato ritirato, se hai necessità diverse': 'The item must be returned at the same corner where it was picked up, unless you have different requirements',
    'L\'oggetto dovrà essere restituito presso il corner dov\'è stato ritirato, se hai necessità diverse': 'The item must be returned to the pickup corner; if you have different needs',
    'La tua recensione': 'Your review',
    'Lascia una recensione': 'Leave a review',
    'Lingua': 'Language',
    'Link utili': 'Useful links',
    'Lunedi': 'Monday',
    'Martedi': 'Tuesday',
    'Mercoledi': 'Wednesday',
    'Musica': 'Music',
    'Ne hai bisogno urgente? Contattaci': 'Need it urgently? Contact us',
    'Negati': 'Denied',
    'Negato': 'Denied',
    'Neonati': 'Newborns',
    'Non hai ancora effettuato prestiti': 'You haven\'t borrowed any items yet',
    'Non hai ricevuto il codice via email? Richiedi': 'Didn\'t receive the code via email? Request it',
    'Non hai ricevuto l\'email? Prova questi suggerimenti del nostro Centro assistenza.': 'Didn\'t receive the email? Try these suggestions from our Help Center.',
    'Non è più possibile annullare questo prestito!': 'It is no longer possible to cancel this borrowed item!',
    'Note': 'Notes',
    'Oggetti': 'Items',
    'Oggetti in evidenza': 'Featured items',
    'Oggetto in manutenzione': 'Item under maintenance',
    'Oggetto in prestito': 'Borrowed item',
    'Oggetto': 'Item',
    'Ops, al momento non è possibile annullare il prestito, riprova più tardi': 'Oops, it is not possible to cancel the borrowed item at the moment. Please try again later.',
    'Orari di apertura': 'Opening hours',
    'Ovviamente non c\'è bisogno di attendere la scadenza del prestito per restituire l\'oggetto, gli altri soci non potranno che esserne felici.': 'Obviously, you do not need to wait until the loan period expires to return the item; other members will be delighted.',
    'Ovviamente non c\'è bisogno di attendere la scadenza del prestito': 'Obviously, you don\'t need to wait until the borrowed item expiration',
    'Passsword aggiornata!': 'Password updated!',
    'Pending': 'Pending',
    'Periodo di prestito richiesto': 'Requested borrowed item period',
    'Periodo di prestito': 'Borrowed item period',
    'Prendi in prestito': 'Borrow',
    'Prenotazione completata': 'Booking completed',
    'Prestito': 'Borrowed item',
    'Profilo': 'Profile',
    'Prossimi giorni di chiusura': 'Upcoming closing days',
    'Prova a effettuare nuovamente la ricerca.': 'Try searching again.',
    'Pulisci filtri': 'Clear filters',
    'Punti di consegna e ritiro': 'Pickup and drop-off points',
    'Punto di riconsegna': 'Return point',
    'Punto di ritiro': 'Pickup point',
    'Qual è il tuo indirizzo email?': 'What is your email address?',
    'Qui puoi consultare lo storico dei tuoi prestiti non appena li avrai effettuati.': 'Here you can view the history of your loans as soon as you make them.',
    'Recap prestito': 'Borrowed item summary',
    'Recensioni': 'Reviews',
    'Recupero password': 'Password recovery',
    'Registrati': 'Register',
    'Regolamento': 'Rules',
    'Reset password': 'Reset password',
    'Restituiti': 'Returned',
    'Restituito': 'Returned',
    'Riceverai una mail di conferma con le istruzioni, controlla nella cartella spam se non ricevi nessuna comunicazione.': 'You will receive a confirmation email with instructions; please check your spam folder if you do not receive any communication.',
    'Ricordati, hai 10 minuti per concludere l\'ordine con PayPal.': 'Remember, you have 10 minutes to complete the order with PayPal.',
    'Rinnova la mia tessera': 'Renew my card',
    'Riprova': 'Retry',
    'Risposte alle tue domande': 'Answers to your questions',
    'Ritiro oggetto': 'Item pickup',
    'Ritorna ai prestiti': 'Return to borrowed items',
    'Sabato': 'Saturday',
    'Sanitari': 'Sanitary Ware',
    'Scegli il punto di ritiro': 'Choose the pickup point',
    'Se hai bisogno del trasporto o di ritirarlo con più urgenza': 'If you require transport or a quicker pickup',
    'Se ne hai bisogno urgente prova a telefonarci e ci mettiamo d\'accordo': 'If you need it urgently, try calling us and we will arrange it',
    'Se questa email è collegata all\'account Leila, potrai reimpostare la password.': 'If this email is linked to your Leila account, you will be able to reset your password.',
    'Se è la prima volta che accedi usa la mail che hai fornito quando ti sei iscritto all\'associazione Leila.': 'If this is your first time logging in, use the email you provided when you joined the Leila association.',
    'Seleziona la lingua': 'Select language',
    'Si trova in': 'Is located in',
    'Specifica tutto in questo spazio, ti risponderemo appena possibile.': 'Specify everything in this space, we will reply as soon as possible.',
    'Sport': 'Sports',
    'Stato': 'Status',
    'Stoviglioteca': 'Tableware',
    'Supporto': 'Support',
    'Tecnologie': 'Technologies',
    'Tessera attiva': 'Active card',
    'Tessera scaduta': 'Expired card',
    'Trovi le istruzioni per il contibuito richiesto nella prossima schermata.': 'You will find the instructions for the required contribution on the next screen.',
    'Tutti gli oggetti': 'All items',
    'Ultimi arrivi': 'New Arrivals',
    'Vedi istruzioni': 'View instructions',
    'Vedi manuale di istruzioni': 'View instruction manual',
    'Vedi tutti': 'See all',
    'Venerdi': 'Friday',
    'Verifica': 'Verify',
    'Vieni a ritirare il tuo oggetto a partire': 'Come pick up your item starting',
    'Vieni a ritirare il tuo oggetto': 'Come pick up your item',
    'Visita il nostro sito': 'Visit our website',
    'Vota selezionando le stelline e scrivi il tuo messaggio': 'Rate by selecting the stars and write your message',
    'Vuoi iniziare a condividere? Entra a far parte della comunità!': 'Want to start sharing? Join the community!',
    'a partire dal': 'starting from',
    'che troviamo una soluzione.': 'we can find a solution.',
    'con un contribuito di': 'with a contribution of',
    'contattaci': 'contact us',
    'dal': 'from',
    'disponibili': 'available',
    'fino al': 'until',
    'oggetti': 'items',
    'per preparare l\'oggetto per il ritiro': 'to prepare the item for pickup',
    'per restituire l\'oggetto, gli altri soci non potranno che esserne felici.': 'to return the item; other members will surely appreciate it.',
    'possedere': 'Owning',
    'Prestiti': 'Borrowed items',
    'prestiti totali': 'total borrowed items',
    'quindi puoi venire a prenderlo': 'so you can come and pick it up',
    'Chiudi': 'Close',
    'Scan': 'Scan item',
    'home_disclaimer': 'With the library of things, you can borrow everything you need for free, without having to buy it!',
    'welcome': 'Happy to see you!',
    'Inizia': 'Get Started',
    'Vedi F.A.Q.': 'See F.A.Q.'
}