<template>
    <router-link
    v-if="to && !href"
    :to="to"
    class="logo bxs-logo"
    :style="{ maxWidth: maxWidth }">
        <img
        :src="src"
        :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} logo`">
    </router-link>

    <a
    v-else
    :href="href"
    :target="target"
    class="logo bxs-logo"
    :style="{ maxWidth: maxWidth }">
        <img
        :src="src"
        :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} logo`">
    </a>
</template>

<script>
export default {
    name: 'logo',
    props: {
        'max-width': {
            type: String,
            required: false,
            default: '80px'
        },
        src: {
            type: String,
            required: false,
            default: '/img/logo-srl.png'
        },
        to: {
            type: String,
            required: false,
            default: '/'
        },
        href: {
            type: String,
            required: false,
            default: null
        },
        target: {
            type: String,
            required: false,
            default: '_blank'
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    vertical-align: middle;

    > img {
        display: block;
        position: relative;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}
</style>