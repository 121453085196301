<template>
    <router-link
    v-if="item.status !== 'pending'"
    exact
    :to="`${to}/${item.id}`">
        <div class="bxs-product-row flex nowrap align-center">
            <div>
                <bxs-figure
                ratio="1"
                width="100"
                :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} ${item.name}`"
                :src="item.img && item.img.url ? $filters.toStaticFile(item.img.url) : item.imgs && item.imgs.length > 0 ? $filters.toStaticFile(item.imgs[0]) : null" />
            </div>

            <div class="flex-1 px-ui">
                <div v-if="!hideInfo">
                    <p
                    v-html="item.name"
                    class="text-500 mb-0"></p>

                    <p class="mb-1 text-mute">
                        <small v-for="(cat, i) in item.categories" :key="cat.origin_id">{{ cat.name }} <span v-if="i < item.categories.length - 1">- </span></small>
                    </p>

                    <div v-if="'loan' in item">
                        <p
                        v-if="item.loan.status === 'busy'"
                        class="small">
                            <span class="text-primary text-500">In prestito </span>
                            <span
                            v-if="item.loan.to_date"
                            class="text-mute ml-1">fino al {{ $filters.toHumanDate(item.loan.to_date) }}</span>
                        </p>

                        <p
                        v-else-if="item.loan.status === 'maintenance'"
                        class="small text-secondary text-uppercase text-500">In manutenzione</p>
                    </div>
                </div>

                <slot />
            </div>

            <div v-if="!hideActions">
                <bxs-icon name="chevron-right"></bxs-icon>
            </div>
        </div>
    </router-link>

    <div v-else>
        <div class="bxs-product-row flex nowrap align-center">
            <div>
                <bxs-figure
                ratio="1"
                width="100"
                :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} ${item.name}`"
                :src="item.img && item.img.url ? $filters.toStaticFile(item.img.url) : item.imgs && item.imgs.length > 0 ? $filters.toStaticFile(item.imgs[0]) : null" />
            </div>

            <div class="flex-1 px-ui">
                <div v-if="!hideInfo">
                    <p
                    v-html="item.name"
                    class="text-500"></p>
                    <p class="mb-1 text-mute">
                        <small v-for="(cat, i) in item.categories" :key="cat.origin_id">{{ cat.name }} <span v-if="i < item.categories.length - 1">- </span></small>
                    </p>

                    <div v-if="'loan' in item">
                        <p
                        v-if="item.loan.status === 'busy'"
                        class="small">
                            <span class="text-primary text-500">In prestito </span>
                            <span
                            v-if="item.loan.to_date"
                            class="text-mute">fino al {{ $filters.toHumanDate(item.loan.to_date) }}</span>
                        </p>

                        <p
                        v-else-if="item.loan.status === 'maintenance'"
                        class="small text-secondary text-uppercase text-500 mt-1">In manutenzione</p>

                        <p
                        v-else-if="item.status === 'pending'"
                        class="small text-tertiary text-uppercase text-500 mt-1">In verifica, attendi la conferma da parte di un amministratore</p>
                    </div>
                </div>

                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'product_row',
    props: {
        item: {
            type: Object,
            required: true
        },
        'hide-actions': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-info': {
            type: Boolean,
            required: false,
            default: false
        },
        to: {
            type: String,
            required: false,
            default: '/products'
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    position: relative;
    display: block;
    text-decoration: none;

    &::before,
    &::after {
        display: none;
    }

    @media (hover: hover) and (pointer: fine) {
        transition: all 0.15s ease;

        &:hover {
            // border: 1px solid rgba(0,0,0,.04);
            border-radius: 4px;
            padding: var(--size-ui);
            box-shadow: 0 22px 24px 0 rgba(0, 0, 0, .08);
        }
    }

    .popular-icon {
        position: absolute;
        width: calc(100% - 20px);
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 1;
        overflow: hidden;
        // display: flex;
        // justify-content: flex-start;
        background-position: center right;
        background-size: contain;
        background-repeat: no-repeat;
    }
}
</style>