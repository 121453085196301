<template>
    <div class="me-orders-view">
        <bxs-top-nav>{{ $t('I tuoi tesseramenti' )}}</bxs-top-nav>

        <bxs-data-lazy
        v-model="data_page"
        :call="() => $leila.me.getMemberships()">
            <section>
                <bxs-layout>
                    <ul>
                        <li
                        v-for="item in data_page"
                        :key="item.id"
                        class="mb-ui">
                            <bxs-card class="pa-ui">
                                <div class="bxs-truncated mb-ui">
                                    <h6>{{ item.card.name }}</h6>
                                </div>

                                <div
                                v-if="item.numero_tessera_convenzionata"
                                class="mb-ui">
                                    <p class="text-mute">Numero tessera convenzionata</p>

                                    <p>{{ item.numero_tessera_convenzionata }}</p>
                                </div>

                                <div class="mb-ui">
                                    <p class="text-mute">Periodo validità</p>

                                    <p>
                                        <span>{{ $filters.toHumanDate(item.sub_date.from) }}</span>
                                        <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                        <span>{{ $filters.toHumanDate(item.sub_date.to) }}</span>
                                    </p>
                                </div>

                                <div>
                                    <p class="text-mute">Data creazione</p>

                                    <p>{{ $filters.toHumanDate(item.origin_created_at, 'LLL') }}</p>
                                </div>
                            </bxs-card>
                        </li>
                    </ul>
                </bxs-layout>
            </section>
        </bxs-data-lazy>

        <!-- categories -->
        <categories-2-section />

        <!-- checkoutbar -->
        <checkoutbar to="/checkout/loan" />
    </div>
</template>

<script>
export default {
    name: 'me_memberships',
    data () {
        return {
            data_page: []
        }
    }
}
</script>