<template>
    <div class="password-recovery-view">
        <bxs-top-nav>{{ $t('Recupero password') }}</bxs-top-nav>

        <section>
            <bxs-layout class="text-center">
                <div class="mb-4">
                    <div v-if="!sucess">
                        <h5>{{ $t('Qual è il tuo indirizzo email?') }}</h5>
                        <p>{{ $t('Se è la prima volta che accedi usa la mail che hai fornito quando ti sei iscritto all\'associazione Leila.') }}</p>
                    </div>

                    <div v-else>
                        <h1 class="text-uppercase">{{ $t('Email invitata') }}</h1>
                        <p>{{ $t('Abbiamo inviato un\'email a') }} <b>{{ email }}</b> {{ $t('Se questa email è collegata all\'account Leila, potrai reimpostare la password.') }}</p>
                    </div>
                </div>

                <div class="mb-ui">
                    <bxs-form
                    v-if="!sucess"
                    ref="form"
                    @submit="submit">
                        <bxs-text-field
                        v-model="email"
                        name="email"
                        :prevent-paste="false"
                        required
                        label="Email"
                        :rules="['email']"
                        class="mb-grid-gutter" />

                        <bxs-btn
                        block
                        color="primary"
                        light
                        :disabled="loading"
                        :loading="loading"
                        @click="$refs.form.submit()">Invia</bxs-btn>
                    </bxs-form>

                    <div v-else>
                        <p>{{ $t('Non hai ricevuto l\'email? Prova questi suggerimenti del nostro Centro assistenza.') }}</p>
                        <bxs-btn @click="restart">{{ $t('Riprova') }}</bxs-btn>
                    </div>
                </div>

                <!-- -->
                <bxs-list>
                    <bxs-list>
                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/signin">{{ $t('Hai già un account? Accedi') }}</bxs-list-item>
                    </bxs-list>
                </bxs-list>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'password_recovery',
    data () {
        return {
            email: null,
            sucess: false,
            loading: false
        }
    },
    methods: {
        async submit () {
            this.loading = true

            if (this.email.includes('@bocconi.it')) {
                return this.$router.push('/signup')
            }

            try {
                await this.$http.post('v1/auths/password_recovery', {
                    email: this.email
                })
                this.$router.push('/password/reset')
            } catch (err) {
                throw new Error(err.message || err.raw)
            } finally {
                this.loading = false
            }
        },
        restart () {
            this.email = null
            this.sucess = false
        }
    }
}
</script>