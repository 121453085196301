<template>
    <div class="me-orders-view">
        <bxs-top-nav>{{ $t('Il tuo prestito' )}}</bxs-top-nav>

        <bxs-data-lazy
        :key="key"
        v-model="data_page"
        :call="() => $leila.me.getOrder($route.params.id)">
            <section>
                <bxs-layout>
                    <bxs-card class="pa-ui">
                        <bxs-row>
                            <bxs-col cols="2">
                                <bxs-figure
                                ratio="1"
                                :src="$filters.toStaticFile(data_page.product.img.url)"
                                max-width="320"
                                class="bxs-pointer mb-ui mx-auto"
                                @click="$router.push('/products/' + data_page.product.id)"></bxs-figure>
                            </bxs-col>

                            <bxs-col
                            cols="10"
                            class="pl-ui">
                                <div class="bxs-truncated mb-ui">
                                    <h6
                                    v-html="data_page.product.name"
                                    class="mb-1"></h6>
                                </div>

                                <div>
                                    <div class="mb-2">
                                        <p class="mb-1 text-mute">{{ $t('Stato' )}}</p>
                                        <p class="text-uppercase">{{ $t($filters.toHumanOrderStatus(data_page.status)) }}</p>
                                    </div>

                                    <div class="mb-2">
                                        <p class="mb-1 text-mute">{{ $t('Data richiesta') }}</p>
                                        <p>{{ $filters.toHumanDate(data_page.created_at) }}</p>
                                    </div>

                                    <div class="mb-2">
                                        <p class="mb-1 text-mute">{{ $t('Periodo di prestito richiesto') }}</p>

                                        <p>
                                            <span>{{ $filters.toHumanDate(data_page.loan.from_date) }}</span>
                                            <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                            <span>{{ $filters.toHumanDate(data_page.loan.to_date) }}</span>
                                        </p>
                                    </div>
                                </div>

                                <div
                                v-if="data_page.note"
                                class="mt-ui">
                                    <div class="mb-1">
                                        <p class="text-mute">{{ $t('Note') }}</p>
                                    </div>

                                    <div v-html="$filters.mdToHtml(data_page.note)"></div>
                                </div>

                                <div
                                v-if="data_page.note_answers"
                                class="mt-ui">
                                    <div class="mb-1">
                                        <h6>{{ $t('Risposte alle tue domande') }}</h6>
                                    </div>

                                    <div v-html="data_page.note_answers"></div>
                                </div>
                            </bxs-col>
                        </bxs-row>
                    </bxs-card>

                    <div class="mt-ui">
                        <div v-if="data_page.status === 'pending'">
                            <bxs-btn
                            color="primary"
                            block
                            light
                            :loading="loading"
                            :disabled="loading"
                            @click="cancel()">{{ $t('Conferma annulla prestito') }}</bxs-btn>

                            <bxs-alert
                            class="mt-ui"
                            v-if="error"
                            type="error">{{ $t('Ops, al momento non è possibile annullare il prestito, riprova più tardi') }}</bxs-alert>
                        </div>

                        <div v-else>
                            <bxs-alert type="info">{{ $t('Non è più possibile annullare questo prestito!') }}</bxs-alert>

                            <!-- help -->
                            <help-section />
                        </div>
                    </div>
                </bxs-layout>
            </section>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'me_order',
    data () {
        return {
            key: 1,
            data_page: null,
            error: null,
            loading: false
        }
    },
    methods: {
        async cancel () {
            this.error = null
            this.loading = true
            this.$store.commit('set_is_loading', true)

            try {
                await this.$leila.me.cancelOrder(this.data_page.id)
                this.$router.replace('/me/orders')
            } catch (err) {
                this.error = err.message || err.name || err
            } finally {
                this.loading = false
                this.$store.commit('set_is_loading', false)
            }
        }
    }
}
</script>