<template>
    <section v-if="!$leila.auth.me">
        <bxs-layout>
            <bxs-btn
            to="/signin"
            block
            color="primary"
            :light="!outlined"
            between
            :outlined="outlined"
            uppercase
            min-height="60px"
            append-icon="chevron-right">
                <!-- <bxs-icon
                name="person-fill"
                class="mr-ui"></bxs-icon> -->

                <span>{{ $t('Accedi e prenota!') }}</span>
            </bxs-btn>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'signin_section',
    props: {
        outlined: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>