<template>
    <div class="scan">
        <bxs-top-nav>Scan</bxs-top-nav>
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <bxs-title>{{ $t('Scansiona il QR Code sull\'oggetto per accedere alla sua pagina') }}</bxs-title>
                    <p>{{ $t('Accetta l\'autorizzazione per accedere alla tua fotocamera.') }}</p>
                </div>

                <div
                v-if="!on"
                class="text-center act-cnt"
                @click="play()">
                    <img src="/img/scan.jpeg" />

                    <bxs-btn
                    v-if="has_cam"
                    light
                    color="black">Inizia</bxs-btn>
                </div>

                <div class="videoWrapper" style="--aspect-ratio: 2 / 3;">
                    <video ref="video"></video>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import QrScanner from 'qr-scanner'

export default {
    name: 'scan',
    data () {
        return {
            on: false,
            has_cam: false
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            this.has_cam = QrScanner.hasCamera()

            this.scanner = new QrScanner(
                this.$refs.video,
                this.onDecode,
                {
                    highlightScanRegion: true,
                    highlightCodeOutline: true,
                    returnDetailedScanResult: true
                    // maxScansPerSecond: 50
                }
            )

            console.log(this.scanner)

            QrScanner.listCameras(true).then((res) => {
                console.log('listCameras()', res)
            })
        },
        play () {
            this.on = true
            this.scanner.start()
        },
        onDecode (evt) {
            console.log('decoded qr code:', evt)

            if (evt.data) {
                this.scanner.stop()
                // window.location.replace(evt.data, '_blank')
                // window.location.href = evt.data
                this.$router.push('/products/' + evt.data)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.act-cnt {
    position: relative;
    // display: flex;
    // flex-flow: row nowrap;
    // align-items: center;
    // justify-content: center;
    img {
        display: inline-block;
        max-width: 400px;
    }

    button {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        margin: auto;
        width: 160px;
        height: 48px;
    }
}

.videoWrapper {
    position: relative;
    padding-bottom: calc(var(--aspect-ratio, .5625) * 100%);
    height: 0;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>