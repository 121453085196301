<template>
    <div class="me-renewal--view">
        <bxs-top-nav>{{ $t('Rinnovo tessera') }}</bxs-top-nav>
        <renew />
    </div>
</template>

<script>
// "buyer": {
//     "email": "sb-uxcap26022810_api1.business.example.com",
//     "password": "325PEPJYCM44YLR7"
// },
// "customer": {
//     "email": "sb-rnvio26284664@personal.example.com",
//     "password": "+W)ZL0}v"
// }

import Renew from '@/components/project/RapidRenew.vue'

export default {
    name: 'me_renewal',
    components: {
        renew: Renew
    }
}
</script>