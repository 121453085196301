import moment from 'moment'
import { parse } from 'marked'
import DOMPurify from 'dompurify'
// const DOMparser = new DOMParser()

moment.locale('it')
const url = new URL(window.location.href)

// function generateZohoPublicFileUrl({
//     filename,
//     report = 'Immagini_Report',
//     report_record_id = '133006000002544081',
//     field_name = 'Image',
//     field_action = 'image-download',
//     base_url = 'https://creatorapp.zohopublic.eu/file/leilaitalia/library-management',
//     report_auth_code = 'FhzaByFderHUXkZsKZWS2U5TvOPbUq3RyfpmS9yNyXWpKh5PaHvRtekmZGuEbZsXhU6wg5472yB7hPJADyj0VbH2sFTCyKHveMaY'
// }) {
//     if (!filename) return ''
//     return `${base_url}/${report}/${report_record_id}/${field_name}/${field_action}/${report_auth_code}?filepath=/${filename}`
// }

// const imageUrl = generateZohoPublicFileUrl({
//   filename: '1721921749464_Copia_di_foto_oggetti_per_web_app_Leila-8.png'
// })
// const pdfUrl = generateZohoPublicFileUrl({
//     base_url: 'https://creatorapp.zohopublic.eu/leilaitalia/library-management/report',
//     report: 'Pubblica_file_articolo',
//     report_record_id: '133006000003344073',
//     field_name: 'Manuale_di_istruzioni',
//     field_action: 'download-file',
//     report_auth_code: 'dNGOG31eRWjxxa8eYgZKOr4DehaGECbPmH05ddVDkmAp89r5HGGCC6mqf7MYrksdqDA0AJBZ9JuSFxt5fABS4M1kbqgYOnrVQb1r',
//     filename: '1731083724747_Livro_8_de_nov._de_2024.pdf&digestValue=eyJsYW5ndWFnZSI6Iml0In0='
// })
// const qrCodeUrl = generateZohoPublicFileUrl({
//     base_url: 'https://creatorapp.zohopublic.eu/leilaitalia/library-management/report',
//     report: 'Pubblica_file_articolo',
//     report_record_id: '133006000003344073',
//     field_name: 'Manuale_di_istruzioni',
//     field_action: 'download-file',
//     report_auth_code: 'dNGOG31eRWjxxa8eYgZKOr4DehaGECbPmH05ddVDkmAp89r5HGGCC6mqf7MYrksdqDA0AJBZ9JuSFxt5fABS4M1kbqgYOnrVQb1r',
//     filename: '1731083724747_Livro_8_de_nov._de_2024.pdf&digestValue=eyJsYW5ndWFnZSI6Iml0In0='
// })
// console.log(imageUrl)
// console.log(pdfUrl)
// console.log(qrCodeUrl)

/*
https://zohocreator-2000021229943-common.zohostratus.eu/133006000000021013/133006000000021079/133006000003917265/133006000004620007/QR_Code.png?signature=VERSION_2-1743061200874-7bb45769fcdf758b6ee5439a7178b8381d8d4816&expiryInSeconds=18000&extraParam=JTdCJTIycmVjb3JkaWQlMjIlM0ElMjIxMzMwMDYwMDAwMDQ2MjAwMDclMjIlMkMlMjJwcml2YXRla2V5JTIyJTNBJTIyRUt2N21RQ3o2R3BTUmg3eGpCWXh2MWVPNkVwUVB4M3l4ejRPM1YwR2hPeGRoeU1LNmtNMkJORnpTVmF0SjEzS1RxQ1pTU3o0ZjhDZGRhR0paZW5VRjFaVm14ZEtkNkRmT3BzeCUyMiUyQyUyMmRpZ2VzdFZhbHVlJTIyJTNBJTIyZXlKc1lXNW5kV0ZuWlNJNkltbDBJbjAlM0QlMjIlMkMlMjJmaWVsZGxpbmtuYW1lJTIyJTNBJTIyUVJfcGlja3VwJTIyJTJDJTIyZmlsZXBhdGglMjIlM0ElMjIxNzQyOTgxNDg4MDAwXzEzMzAwNjAwMDAwNDYyMDAwN19RUl9Db2RlLnBuZyUyMiUyQyUyMmFwcGxpbmtuYW1lJTIyJTNBJTIybGlicmFyeS1tYW5hZ2VtZW50JTIyJTJDJTIybW9kdWxlJTIyJTNBJTIycmVwb3J0cyUyMiUyQyUyMmxvZ2luWnVpZCUyMiUzQSUyMi0xMDAlMjIlMkMlMjJ2aWV3bGlua25hbWUlMjIlM0ElMjJQdWJibGljYV9maWxlX1ByZXN0aXRvJTIyJTJDJTIyc2NvcGVuYW1lJTIyJTNBJTIybGVpbGFpdGFsaWElMjIlMkMlMjJpc1B1YmxpYyUyMiUzQXRydWUlMkMlMjJpc0F0dGFjaG1lbnQlMjIlM0FmYWxzZSU3RA%3D%3D
https://zohocreator-2000021229943-common.zohostratus.eu/133006000000021013/133006000000021079/133006000003916145/133006000004620007/QR_Code.png?signature=VERSION_2-1743061200903-8f5b47d646b6953a5ef1933f86edb87ab5301ec7&expiryInSeconds=18000&extraParam=JTdCJTIycmVjb3JkaWQlMjIlM0ElMjIxMzMwMDYwMDAwMDQ2MjAwMDclMjIlMkMlMjJwcml2YXRla2V5JTIyJTNBJTIyRUt2N21RQ3o2R3BTUmg3eGpCWXh2MWVPNkVwUVB4M3l4ejRPM1YwR2hPeGRoeU1LNmtNMkJORnpTVmF0SjEzS1RxQ1pTU3o0ZjhDZGRhR0paZW5VRjFaVm14ZEtkNkRmT3BzeCUyMiUyQyUyMmRpZ2VzdFZhbHVlJTIyJTNBJTIyZXlKc1lXNW5kV0ZuWlNJNkltbDBJbjAlM0QlMjIlMkMlMjJmaWVsZGxpbmtuYW1lJTIyJTNBJTIyUVJfZGVwb3NpdCUyMiUyQyUyMmZpbGVwYXRoJTIyJTNBJTIyMTc0Mjk4MTQ4ODAwMF8xMzMwMDYwMDAwMDQ2MjAwMDdfUVJfQ29kZS5wbmclMjIlMkMlMjJhcHBsaW5rbmFtZSUyMiUzQSUyMmxpYnJhcnktbWFuYWdlbWVudCUyMiUyQyUyMm1vZHVsZSUyMiUzQSUyMnJlcG9ydHMlMjIlMkMlMjJsb2dpblp1aWQlMjIlM0ElMjItMTAwJTIyJTJDJTIydmlld2xpbmtuYW1lJTIyJTNBJTIyUHViYmxpY2FfZmlsZV9QcmVzdGl0byUyMiUyQyUyMnNjb3BlbmFtZSUyMiUzQSUyMmxlaWxhaXRhbGlhJTIyJTJDJTIyaXNQdWJsaWMlMjIlM0F0cnVlJTJDJTIyaXNBdHRhY2htZW50JTIyJTNBZmFsc2UlN0Q%3D

[ok] https://creatorapp.zohopublic.eu/file/leilaitalia/library-management/Immagini_Report/133006000002544081/Image/image-download/FhzaByFderHUXkZsKZWS2U5TvOPbUq3RyfpmS9yNyXWpKh5PaHvRtekmZGuEbZsXhU6wg5472yB7hPJADyj0VbH2sFTCyKHveMaY?filepath=/1721921749464_Copia_di_foto_oggetti_per_web_app_Leila-8.png
[ok] https://creatorapp.zohopublic.eu/leilaitalia/library-management/report/Pubblica_file_articolo/133006000003344073/Manuale_di_istruzioni/download-file/dNGOG31eRWjxxa8eYgZKOr4DehaGECbPmH05ddVDkmAp89r5HGGCC6mqf7MYrksdqDA0AJBZ9JuSFxt5fABS4M1kbqgYOnrVQb1r?filepath=/1731083724747_Livro_8_de_nov._de_2024.pdf&digestValue=eyJsYW5ndWFnZSI6Iml0In0=

//  https://creatorapp.zohopublic.eu/leilaitalia/library-management/report/Pubblica_file_articolo/133006000004633475/Manuale_di_istruzioni/download-file/dNGOG31eRWjxxa8eYgZKOr4DehaGECbPmH05ddVDkmAp89r5HGGCC6mqf7MYrksdqDA0AJBZ9JuSFxt5fABS4M1kbqgYOnrVQb1r?filepath=/1743072072001_istruzioni_cluedo_2.pdf&digestValue=eyJsYW5ndWFnZSI6Iml0In0=
//  https://creatorapp.zohopublic.eu/leilaitalia/library-management/report/Pubblica_file_articolo/133006000004290089/Manuale_di_istruzioni/download-file/dNGOG31eRWjxxa8eYgZKOr4DehaGECbPmH05ddVDkmAp89r5HGGCC6mqf7MYrksdqDA0AJBZ9JuSFxt5fABS4M1kbqgYOnrVQb1r?filepath=/1739807847237_A1LumV7c8BL.pdf&digestValue=eyJsYW5ndWFnZSI6Iml0In0=
//  https://creatorapp.zohopublic.eu/leilaitalia/library-management/report/Pubblica_file_articolo/133006000003344073/Manuale_di_istruzioni/download-file/dNGOG31eRWjxxa8eYgZKOr4DehaGECbPmH05ddVDkmAp89r5HGGCC6mqf7MYrksdqDA0AJBZ9JuSFxt5fABS4M1kbqgYOnrVQb1r?filepath=/1731083724747_Livro_8_de_nov._de_2024.pdf&digestValue=eyJsYW5ndWFnZSI6Iml0In0=
*/

const d = {
    toSafeHTML(v) {
        let cleanedHtml = v.replace(/&nbsp;|&#160;/g, ' ')
        cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim()
        const value = DOMPurify.sanitize(cleanedHtml)
        return value.replace(/\s+/g, ' ')
    },
    toHumanDate(value, f = 'L', add_days = 0) {
        return value && moment(value).isValid() ? moment(value).add(add_days, 'd').format(f) : (value || '--')
    },
    toHumanDateNextMonth(value, f = 'L') {
        return value && moment(value).isValid() ? moment(value).add(1, 'M').format(f) : (value || '--')
    },
    toHumanCalendar(value) {
        return value ? moment(value).calendar() : (value || '--')
    },
    dateIsBeforeNow(value, add_days = 0) {
        return moment(value).add(add_days, 'd').isBefore(moment())
    },
    mdToHtml: v => parse(v),
    isMajorOfNow(value) {
        if (!value || !moment(value).isValid()) return value
        return moment(value).diff(moment()) >= 0
    },
    truncate(v, l = 115) {
        if (!v) return v
        return v.substring(0, l) + '...'
    },
    highlight(v, k) {
        if (!v || !k) return v
        return v.replace(k, '<span class="text-highlight">' + k + '</span>')
    },
    toHumanOrderStatus(v) {
        let _v = v

        if (v === 'checkout') _v = 'Checkout in corso'
        else if (v === 'pending') _v = 'Attesa conferma'
        else if (v === 'approved') _v = 'Approvato'
        else if (v === 'extended') _v = 'Esteso'
        else if (v === 'cancelled') _v = 'Annullato'
        else if (v === 'denied') _v = 'Negato'
        else if (v === 'returned') _v = 'Restituito'

        return _v
    },
    toStaticFile(v) {
        if (!v) return v
        // const env = process.env.NODE_ENV
        // if (v.startsWith('http')) v = v.replace('http://localhost:8080/', '')
        return `${process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API : url.origin}/${v}`
    },
    toStaticFileMaster(v) {
        if (!v) return v
        // const env = process.env.NODE_ENV
        // if (v.startsWith('http')) v = v.replace('http://localhost:8080/', '')
        return `${process.env.VUE_APP_API_MASTER}/${v}`
    }
}

export default d