<template>
    <section class="help-section bxs-pointer">
        <bxs-layout>
            <bxs-btn
            to="/help"
            outlined
            color="fourth"
            block
            between
            min-height="60px"
            append-icon="chevron-right">{{ $t('Bisogno di aiuto? Contattaci') }}</bxs-btn>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'help-section'
}
</script>